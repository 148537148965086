import React, {useRef, useState} from "react";
import {useSize} from "ahooks";
import {Card, Flex, Typography, theme, Button} from "antd";
import {
    AlertFilled,
    DownOutlined,
    FundFilled,
    StarFilled,
    UpOutlined,
} from "@ant-design/icons";
import Localizator from "@global/Localizator";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsInline from "@components/retail/cards/StatsInline";
import NestedCard from "@components/retail/cards/NestedCard";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import CardHeader from "@components/retail/cards/CardHeader";
import LockTooltip, {
    LockPlaceholder
} from "@components/retail/cards/LockTooltip";
import {useAuth} from "@hooks/AuthHook";
import TourButton from "@components/retail/cards/TourButton";


const {useToken} = theme;


const SCENARIOS = [
    "interest rate hike 2022",
    // "bear market 2022",
    "bull market 2021",
    "2020 stock market crash",
    // "interest rate hike",
    "flat market 2015",
    "2013 bull market",
    "financial crisis",
    // "2008 bear market",
];


export const prepareScenarios = (proposal) => {
    const scenariosCurrent = proposal?.p_bucket_cur?.calculated_values?.risk?.stress_test ?? [];
    const scenariosProposed = proposal?.p_bucket_prop?.calculated_values?.risk?.stress_test ?? [];
    const scenariosPrepared = [];

    for (let periodLabel of SCENARIOS) {
        const scenarioCurrent = scenariosCurrent.find(item => item.period_label === periodLabel && item.benchmark_label === "iShares Russell 3000 ETF (IWV)");
        const scenarioProposed = scenariosProposed.find(item => item.period_label === periodLabel && item.benchmark_label === "iShares Russell 3000 ETF (IWV)");
        const scenarioCurrentVsBond = scenariosCurrent.find(item => item.period_label === periodLabel && item.benchmark_label === "iShares US Aggregate Bond ETF (AGG)");
        const scenarioProposedVsBond = scenariosProposed.find(item => item.period_label === periodLabel && item.benchmark_label === "iShares US Aggregate Bond ETF (AGG)");
        const scenarioPrepared = {};

        if (!scenarioCurrent && !scenarioProposed) {
            continue;
        }

        scenarioPrepared.label = scenarioCurrent?.period_label ?? scenarioProposed?.period_label;
        scenarioPrepared.start = scenarioCurrent?.start ?? scenarioProposed?.start;
        scenarioPrepared.end = scenarioCurrent?.end ?? scenarioProposed?.end;
        scenarioPrepared.stockMarketReturn = scenarioCurrent?.benchmark_return ?? scenarioProposed?.benchmark_return;
        scenarioPrepared.bondMarketReturn = scenarioCurrentVsBond?.benchmark_return ?? scenarioProposedVsBond?.benchmark_return;
        scenarioPrepared.currentPortfolioReturn = scenarioCurrent?.portfolio_return;
        scenarioPrepared.proposedPortfolioReturn = scenarioProposed?.portfolio_return;

        const r = [
            scenarioPrepared.stockMarketReturn,
            scenarioPrepared.bondMarketReturn,
            scenarioPrepared.currentPortfolioReturn,
            scenarioPrepared.proposedPortfolioReturn
        ].filter(x => !is_null(x));
        const rMax = Math.max(...r);
        const rMin = Math.min(...r);
        const delta = rMax - rMin;
        const step = delta / 4;

        scenarioPrepared.stockMarketScore = 1 + Math.floor((scenarioPrepared.stockMarketReturn - rMin) / step);
        scenarioPrepared.bondMarketScore = 1 + Math.floor((scenarioPrepared.bondMarketReturn - rMin) / step);
        scenarioPrepared.currentPortfolioScore = 1 + Math.floor((scenarioPrepared.currentPortfolioReturn - rMin) / step);
        scenarioPrepared.proposedPortfolioScore = 1 + Math.floor((scenarioPrepared.proposedPortfolioReturn - rMin) / step);

        scenariosPrepared.push(scenarioPrepared);
    }
    return scenariosPrepared;
}


export const PerformanceStats = ({
                                     label,
                                     portfolioReturn,
                                     marketValue,
                                     portfolioScore,
                                     background = "none",
                                     style = {},
                                     id
                                 }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();
    const pVal = (portfolioReturn > 0 ? "+" : "")
        + ValueFormatter.oneDigitPercent(portfolioReturn / 100);
    const dVal = (portfolioReturn > 0 ? "+" : "")
        + ValueFormatter.compactCurrency(marketValue * portfolioReturn / 100);

    return <Flex
        gap={"small"}
        justify={"space-between"}
        align={"flex-start"}
        wrap={"wrap"}
        style={{
            minWidth: 120,
            padding: "16px 24px",
            borderRadius: token.borderRadiusLG,
            background: hover ? token.colorBgHoverBlue : background,
            border: `1px solid ${token.colorBorderStrong}`,
            flexGrow: 1,
            ...style
        }}
        id={id}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <Flex vertical gap={4}>
            <span style={{
                fontSize: token.fontSizeSM,
                fontWeight: token.fontWeightStrong,
                lineHeight: 1.1,
            }}>
                {label}
            </span>
            <Flex gap={2}>
                {Array.from(Array(5)).map((_, i) => {
                    return <StarFilled key={i} style={{
                        color: i < portfolioScore ? "#eeba6c" : "rgba(0,0,0,0.1)",
                        fontSize: 10
                    }}/>
                })}
            </Flex>
        </Flex>
        <Flex vertical gap={4}>
             <span style={{
                 fontSize: token.fontSizeHeading4,
                 lineHeight: 1,
                 color: portfolioReturn >= 0 ? "rgb(73,170,25)" : "rgb(245,34,45)"
             }}>
                {pVal}
            </span>
            <span style={{color: token.colorTextDescription}}>
                {dVal}
            </span>
        </Flex>
    </Flex>
}


export const StressTestTip = ({
                                  scenarioLabel,
                                  portfolioReturn,
                                  stockMarketReturn,
                                  bondMarketReturn,
                                  portfolioLabel = "my portfolio"
                              }) => {
    return <>{is_null(portfolioReturn)
        ? "No performance data"
        : <>
            In the case of
            {" " + Localizator.t("stress." + scenarioLabel).toLowerCase() + " "}
            scenario, {portfolioLabel} would probably
            {portfolioReturn >= stockMarketReturn
                && portfolioReturn >= bondMarketReturn
                && " beat both the stock and bond markets."
            }
            {portfolioReturn >= stockMarketReturn
                && portfolioReturn < bondMarketReturn
                && " beat the stock market, but lose to the bond market."
            }
            {portfolioReturn < stockMarketReturn
                && portfolioReturn >= bondMarketReturn
                && " lose to the stock market, but beat the bond market."
            }
            {portfolioReturn < stockMarketReturn
                && portfolioReturn < bondMarketReturn
                && " loose to both the stock and bond markets."
            }
        </>
    }</>
}


const StressTestItem = ({
                            item,
                            marketValue,
                            vertical,
                            performanceCardBackground,
                            locked = false,
                            id
                        }) => {
    const {token} = useToken();

    return <LockTooltip label={"Stress tests"} plural blur locked={locked}>
        <Flex vertical align={"flex-start"}>
            <div id={`${id}-scenario-description`}>
                < Typography.Title
                    level={5}
                    style={{
                        margin: 0,
                        textTransform: "uppercase",
                        letterSpacing: 1,
                        fontWeight: 350
                    }}
                >
                    {Localizator.t("stress." + item.label)}
                </Typography.Title>
                <Typography.Text
                    type={"secondary"}
                    style={{
                        fontSize: token.fontSizeSM,
                        marginBottom: token.paddingSM,
                    }}
                >
                    {item.start + ' — ' + item.end}
                </Typography.Text>
            </div>
            <Typography.Paragraph
                style={{maxWidth: 500, marginBottom: 12}}
                id={`${id}-text-result`}
            >
                <StressTestTip
                    scenarioLabel={item.label}
                    portfolioReturn={item.currentPortfolioReturn}
                    stockMarketReturn={item.stockMarketReturn}
                    bondMarketReturn={item.bondMarketReturn}
                />
            </Typography.Paragraph>
            <Flex vertical={vertical} gap={"small"}
                  style={{width: "100%", paddingBottom: 16}}>
                <PerformanceStats
                    label={<>My<br/>portfolio</>}
                    portfolioReturn={item.currentPortfolioReturn}
                    marketValue={marketValue}
                    portfolioScore={item.currentPortfolioScore}
                    background={performanceCardBackground}
                    id={`${id}-portfolio-result`}
                />
                <PerformanceStats
                    label={<>Stock<br/>market</>}
                    portfolioReturn={item.stockMarketReturn}
                    marketValue={marketValue}
                    portfolioScore={item.stockMarketScore}
                    background={performanceCardBackground}
                    id={`${id}-stocks-result`}
                />
                <PerformanceStats
                    label={<>Bond<br/>market</>}
                    portfolioReturn={item.bondMarketReturn}
                    marketValue={marketValue}
                    portfolioScore={item.bondMarketScore}
                    background={performanceCardBackground}
                    id={`${id}-bonds-result`}
                />
            </Flex>
        </Flex>
    </LockTooltip>
}


const StressTestItemRecursive = ({
                                     idx,
                                     scenarios,
                                     marketValue,
                                     vertical,
                                     locked = false,
                                     children
                                 }) => {
    const {token} = useToken();

    return <>{scenarios.length === 0
        ? children
        :
        <StressTestItemRecursive
            idx={idx + 1}
            scenarios={scenarios.filter((_, i) => i > 0)}
            marketValue={marketValue}
            vertical={vertical}
            locked={locked}
        >
            <NestedCard
                background={idx % 2 ? token.colorBgContainer : token.colorBgGrey}>
                {children}
                <StressTestItem
                    item={scenarios[0]}
                    marketValue={marketValue}
                    performanceCardBackground={idx % 2 ? token.colorBgGrey : token.colorBgContainer}
                    vertical={vertical}
                    locked={locked}
                    id={`stress-test-item-${idx}`}
                />
            </NestedCard>
        </StressTestItemRecursive>
    }</>
}


const StressTestCardTour = ({}) => {
    const steps = [
        {
            title: 'Stress test result for your portfolio',
            description: "The more points the better your portfolio behaves in different scenarios",
            target: "stress-test-points",
        },
        {
            title: "Scenario name and historical period",
            target: "stress-test-item-0-scenario-description",
        },
        {
            title: "Your portfolio in comparison with the stock and bond markets",
            target: "stress-test-item-0-text-result",
        },
        {
            title: "Estimated portfolio performance for this scenario",
            target: "stress-test-item-0-portfolio-result",
        },
        {
            title: "Stock market (IWV ETF) performance for this scenario",
            target: "stress-test-item-0-stocks-result",
        },
        {
            title: "Bond market (AGG ETF) performance for this scenario",
            target: "stress-test-item-0-bonds-result",
        },
        {
            title: "Click to show more/less scenarios",
            target: "stress-test-show-all",
        },
    ];

    return <TourButton steps={steps}/>
}


const StressTestCard = ({collapsible = true}) => {
    const {isDataLocked} = useAuth();
    const {proposal} = useHolisticoProposalContext();
    const [expanded, setExpanded] = useState(false);
    const {token} = useToken();
    const ref = useRef(null);
    const size = useSize(ref);
    const vertical = size?.width < 400;
    const {sendGAEvent} = useAuth();

    const cv = proposal?.p_bucket_cur?.calculated_values;
    const scenarios = prepareScenarios(proposal);
    const score = scenarios?.reduce((s, a) => s + a.currentPortfolioScore, 0);
    const scoreMax = scenarios?.length * 5;
    const nScenariosToShow = 1;
    let scenariosToShow = scenarios;
    if (collapsible && scenarios && !expanded) {
        scenariosToShow = scenarios.filter((_, i) => i < nScenariosToShow);
    }

    const locked = isDataLocked("#portfolio-stress-tests");

    return <Card
        hoverable
        ref={ref}
        style={{
            borderColor: token.colorBorderStrong,
            background: locked ? token.colorBgContainer : scenariosToShow.length % 2 ? token.colorBgContainer : token.colorBgGrey,
            cursor: "default"
        }}
        id={"stress-test-card"}
    >
        <>
            {scenariosToShow && scenariosToShow.length > 0
                ?
                <StressTestItemRecursive
                    idx={0}
                    scenarios={scenariosToShow}
                    marketValue={cv.market_value}
                    vertical={vertical}
                    locked={locked}
                >
                    <NestedCard background={token.colorBgContainer}>
                        <CardHeader
                            title="Stress tests"
                            icon={<FundFilled/>}
                            tour={<StressTestCardTour/>}
                            id={"stress-test-card-header"}
                        />
                        <StatsInline title={"points"}
                                     value={score + " / " + scoreMax}
                                     id={"stress-test-points"}/>
                    </NestedCard>
                </StressTestItemRecursive>
                :
                <>
                    <NestedCard background={token.colorBgContainer}>
                        <Typography.Title level={4}
                                          style={{margin: "0 0 12px 0"}}>
                            Stress tests
                        </Typography.Title>
                    </NestedCard>
                    <NoDataPlaceholder/>
                </>

            }
            <LockTooltip label={"Stress tests"} plural blur locked={locked}>
                {collapsible && scenarios && scenarios.length > nScenariosToShow
                    &&
                    <Button
                        onClick={() => {
                            setExpanded(prev => !prev);
                            sendGAEvent({
                                category: 'StressTest',
                                action: 'Click',
                                label: 'Show more/less scenarios'
                            });
                        }}
                        type={"link"}
                        size={"small"}
                        icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                        style={{paddingLeft: 0}}
                        id={"stress-test-show-all"}
                    >
                        {expanded ? "Show less scenarios" : `Show more scenarios`}
                    </Button>
                }
            </LockTooltip>
        </>
    </Card>
}


export default StressTestCard;
