import {
    Button,
    Col,
    Flex,
    Modal,
    Row,
    Space,
    theme,
    Tour,
    Typography
} from "antd";
import SectorCard from "@holistico/welcome/items/SectorCard";
import React, {useMemo, useState} from "react";
import FactorPerformanceCard
    from "@holistico/welcome/items/FactorPerformanceCard";
import MacroStatsCard from "@holistico/welcome/items/MacroStatsCard";
import MacroChartCard from "@holistico/welcome/items/MacroChartCard";
import MacroOverview from "@holistico/welcome/items/MacroOverview";
import ScreenerCard from "@holistico/welcome/items/ScreenerCard";
import MyPortfolioCard from "@components/retail/welcome/MyPortfolioCard";
import {useAuth} from "@hooks/AuthHook";
import {BulbOutlined} from "@ant-design/icons";
import {
    PageTourModal,
} from "@components/retail/cards/TourButton";


const {useToken} = theme;


const WelcomePageTour = ({}) => {
    const {openTour, setOpenTour} = useAuth();
    const {token} = useToken();

    const steps = [
        {
            title: "Portfolio snapshot",
            description: <>
                Here you can find the summary of portfolio analysis
            </>,
            target: "portfolio-card-header-title",
        },
        {
            title: "Click to add accounts and get portfolio analytics",
            target: "my-portfolio-card-add-accounts",
        },
        {
            title: "Click to open tour for this card and see more details",
            description: <>
                All the cards on this page has educational tour.
                To open it, find and click {" "}
                <BulbOutlined/>
                {" " } icon
            </>,
            target: "portfolio-card-header-tour",
        },
        {
            title: "Click to discuss your portfolio with Holi, our AI assistant",
            target: "holi-float-button"
        },
        {
            title: "Macroeconomic statistics",
            description: <>
                Here you can find latest macroeconomic statistics and
                Holi's comment on it
            </>,
            target: "macro-stats-card-item-0",
        },
        {
            title: "Market indicators",
            description: <>
                Here you can find Market indicators charts
                and Holi's comments on them
            </>,
            target: "macro-chart-card-menu",
        },
        {
            title: "Sector forecasts",
            description: <>
                Here you can find proprietary sector forecasts
                for the next 12 months
            </>,
            target: "sector-forecast-card-header-title",
        },
        {
            title: "Market monitor",
            description: <>
                Here you can find Holi's comment on the current
                macroeconomic environment
            </>,
            target: "market-monitor-card-header-title",
        },
        {
            title: "Factor performance",
            description: <>
                Here you can find factors historical alpha
            </>,
            target: "factor-performance-card-header-title",
        },
        {
            title: "Screener",
            description: <>
                Here you can find links to the screener collections
            </>,
            target: "screener-card-header-title",
        },
    ];

    const existingSteps = useMemo(() => {
        const _steps = [];
        steps.map(step => {
            if(document.getElementById(step.target)) {
                _steps.push({...step, target: () => document.getElementById(step.target)});
            }
        })
        return _steps;
    }, [openTour])

    return <Tour
        type="primary"
        open={openTour === "dashboard-page"}
        onClose={() => setOpenTour(null)}
        steps={existingSteps}
        gap={{radius: token.borderRadius, offset: token.marginXS}}
        zIndex={10010}
        disabledInteraction={true}
    />
}


const WelcomePage = () => {
    const {token} = useToken();

    return <>
        <Row gutter={[token.paddingSM, token.paddingSM]} align={"stretch"}>
            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                <Row
                    gutter={[token.paddingSM, token.paddingSM]}
                    // style={{height: "100%"}}
                >
                    <Col xs={24} sm={24} md={10} lg={24} xl={24}>
                        <MyPortfolioCard/>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={24} xl={24}>
                        <ScreenerCard/>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={18}>
                <Flex vertical gap={token.paddingSM}>
                    <MacroStatsCard/>
                    <Row gutter={[token.paddingSM, token.paddingSM]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <MacroChartCard
                                title={null}
                                fromDate={"1980-01-31"}
                                columns={[
                                    "vg_spread_1yr",
                                    "sl_spread_1yr",
                                    "equal_vs_cap_weighted_spread_1yr",
                                    "valuation_spread_sp500_sp400",
                                    "yield_curve_slope_10yr_vs_3m",
                                ]}
                                defaultColumn={"vg_spread_1yr"}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <SectorCard/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                            <MacroOverview/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <FactorPerformanceCard/>
                        </Col>
                    </Row>
                </Flex>
            </Col>
        </Row>
        <WelcomePageTour/>
        <PageTourModal tourKey={"dashboard-page"}/>
    </>
}

export default WelcomePage;
