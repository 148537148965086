/* eslint-disable react/prop-types */
import React from 'react';
import {Card, Collapse, Flex} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {FILTER_DISPLAY_SETTINGS} from "@components/screener/settings";
import NumericFilter from "@components/screener/params/NumericFilter";
import AllocationFilter from "@components/screener/params/AllocationFilter";
import ChoiceFilter from "@components/screener/params/ChoiceFilter";
import CollectionFilter from "@components/screener/params/CollectionFilter";
import Localizator from "@global/Localizator";
import {useAuth} from "@hooks/AuthHook";
import LockTooltip from "@components/retail/cards/LockTooltip";
import {AssetScreenerTour} from "@root/site/Screener";
import {PageTourModal} from "@components/retail/cards/TourButton";


const RATINGS = [
    'sscore_plus',
    'macro_score_1q',
    'macro_score_1yr',
    'sscore_plus_yield',
    'sscore_plus_value',
    'sscore_plus_momentum',
    'sscore_plus_profitability',
    'sscore_plus_safety',
    'sscore_plus_growth',
    'z_score',
]

const ALLOCATIONS = [
    'asset_class',
    'sector',
    'size',
    'style',
    'fi_sector',
    'fi_credit_rating',
    'fi_maturity',
]


const FiltersPanelItem = ({item, label, entityGroup, id}) => {
    const {isDataLocked} = useAuth();
    const name = item.name;
    const type = item.type;

    let locked = false;
    if (entityGroup === 'company' || entityGroup === 'fund') {
        if (name === 'div_yield' && isDataLocked("#security-dividend-yield"))
            locked = true;
        else if (name === 'expense_ratio' && isDataLocked("#security-expense-ratio"))
            locked = true;
        else if (RATINGS.includes(name) && isDataLocked("#security-ratings"))
            locked = true;
        else if (ALLOCATIONS.includes(name) && isDataLocked("#security-allocations"))
            locked = true;
    } else if (entityGroup === 'portfolio') {
        if (name === 'div_yield' && isDataLocked("#model-dividend-yield"))
            locked = true;
        else if (name === 'expense_ratio' && isDataLocked("#model-expense-ratio"))
            locked = true;
        else if (RATINGS.includes(name) && isDataLocked("#model-ratings"))
            locked = true;
        else if (ALLOCATIONS.includes(name) && isDataLocked("#model-allocations"))
            locked = true;
    }

    return <LockTooltip
        label={label}
        stuff={"data"}
        locked={locked}
    >
        {type === 'choice' && name === 'collection'
            &&
            <CollectionFilter item={item} disabled={locked} id={id}/>
        }
        {type === 'choice' && name !== 'collection'
            &&
            <ChoiceFilter item={item} showMax={item.showMax} disabled={locked} id={id}/>
        }
        {type === 'numeric'
            &&
            <NumericFilter item={item} disabled={locked} id={id}/>
        }
        {type === 'allocation'
            &&
            <AllocationFilter item={item} disabled={locked} id={id}/>
        }
    </LockTooltip>
}


const FiltersPanel = ({showTour=false}) => {
    const {entityGroup, settings} = useScreenerContext()
    const displaySettings = FILTER_DISPLAY_SETTINGS[entityGroup]
    const expanded = Object.keys(settings.filters)
        .filter(key => (displaySettings[key]?.expanded))

    return <Card style={{borderColor: "#e3e3e3"}}>
        {showTour
            && <Flex justify={"flex-end"} style={{marginBottom: -32}}>
                <AssetScreenerTour buttonStyle={{position: "relative", zIndex: 10}}/>
            </Flex>
        }
        <Collapse
            defaultActiveKey={expanded}
            ghost
            items={
                Object.keys(displaySettings).map((key, idx) => {
                    const label = Localizator.t(`screener.${entityGroup}.${key}`);
                    return {
                        key: key,
                        label: label,
                        id: `screener-filter-item-${idx}`,
                        children: <FiltersPanelItem
                            item={{
                                ...settings.filters[key],
                                ...displaySettings[key]
                            }}
                            label={label}
                            entityGroup={entityGroup}
                            id={`screener-filter-item-${idx}-content`}
                        />
                    }
                })
            }
        />
    </Card>
}


export default FiltersPanel;
