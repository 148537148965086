import React, {useState} from "react";
import {
    Button,
    Card, Flex, Spin,
    theme,
    Typography
} from 'antd';
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useRequest, useUpdateEffect} from "ahooks";
import {
    getMacroHistorySeparate,
    getMarketMonitorComment
} from "@API/ahooks/dashboard";
import dayjs from "dayjs";
import {useAuth} from "@hooks/AuthHook";
import TourButton from "@components/retail/cards/TourButton";


const {useToken} = theme;

const MacroOverviewCardTour = ({}) => {
    const steps = [
        {
            title: "Holi's comment on the macroeconomic situation",
            target: "macro-overview-card-text",
        },
        {
            title: "Click to see full comment",
            target: "macro-overview-card-read-more",
        },
    ];

    return <TourButton steps={steps}/>
}


const MacroOverview = ({}) => {
    const [text, setText] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getMarketMonitorComment());

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('Error while loading macroeconomic data')
        }
    }, [error])

    useUpdateEffect(() => {
        console.debug("MacroOverview data loaded", response.data);
        setText(response.data.comment ?? "");
    }, [response])

    return <Card hoverable style={{
        height: "100%",
        cursor: "default",
    }}>
        <Flex justify={"space-between"}>
            <Typography.Title level={4} style={{margin: 0}} id={"market-monitor-card-header-title"}>
                Market monitor
            </Typography.Title>
            <MacroOverviewCardTour/>
        </Flex>
        <Typography.Text type={"secondary"} style={{
            display: "block",
            marginBottom: token.padding
        }}>
            By Holi
        </Typography.Text>
        <div id={"macro-overview-card-text"}>
            <Spin spinning={loading}>
                {text.split("\n")
                    .filter((_, i) => i < 2)
                    .map((par, index) => {
                        return <Typography.Paragraph key={index}>
                            {par}
                        </Typography.Paragraph>
                    })}
            </Spin>
        </div>
        <Button
            type={"link"}
            onClick={() => {
                setOpenDrawer(true);
                sendGAEvent({
                    category: 'Macro Overview',
                    action: 'Click',
                    label: 'Read more...'
                });
            }}
            style={{padding: 0}}
            id={"macro-overview-card-read-more"}
        >
            Read more...
        </Button>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={600}
        >
            <Card>
                <Typography.Title level={4} style={{margin: 0}}>
                    Market monitor
                </Typography.Title>
                <Typography.Text type={"secondary"} style={{
                    display: "block",
                    marginBottom: token.padding
                }}>
                    By Holi
                </Typography.Text>
                {text.split("\n").map((par, index) => {
                    return <Typography.Paragraph key={index}>
                        {par}
                    </Typography.Paragraph>
                })}
            </Card>
        </MobileDrawer>
    </Card>

}

export default MacroOverview;
