import React from "react";
import {Alert, Button, Flex, theme, Typography} from "antd";
import {useLocation} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


const FreeSubscriptionAlert = ({}) => {
    const {currentSubscription, accessDetails} = useAuth();
    const {token} = useToken();
    const location = useLocation();

    const subscriptionKey = accessDetails?.test_settings_enabled
        ? accessDetails?.test_settings_subscription_key
        : currentSubscription?.ui_product_key;

    if (subscriptionKey !== "free") {
        return null;
    }
    if(accessDetails.subscription_expired) {
        return null
    }
    if (location.pathname?.startsWith("/individual/profile")) {
        return null;
    }
    const end_date = new Date(currentSubscription.current_period_end * 1000);
    const formatted_date = end_date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return <Alert
        message={<>
            <Typography.Text style={{marginRight: token.marginXS}}>
                You're using free version of Holistico, which will expire
                on {formatted_date}.
                To continue using Holistico after this date
                and reveal deeper analytics, try one of our paid subscriptions:
            </Typography.Text>
            <a href={"/individual/profile/subscription"}>
                <Button type={"link"} style={{padding: 0}}>
                    View subscription plans
                </Button>
            </a>
        </>}
        type={"warning"}
        style={{marginBottom: token.margin}}
    />

}

export default FreeSubscriptionAlert;
