/* eslint-disable react/prop-types */
import React from 'react';
import {Empty, List, Button, Flex} from "antd";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {CompanyCard} from "@components/screener/card/CompanyCard";
import FundCard from "@components/screener/card/FundCard";
import PortfolioCard from "@components/screener/card/PortfolioCard";
import {useAuth} from "@hooks/AuthHook";


const AssetsList = () => {
    const {isDataLocked} = useAuth();
    const {
        entityGroup,
        settings,
        assets,
        entityGroupLabelPl,
        limit,
        loadingAssets,
        fetchMoreAssets,
        loadingMoreAssets
    } = useScreenerContext();

    return <>{settings
        &&
        <>
            <List
                loading={{spinning: loadingAssets, tip: `Searching ${entityGroupLabelPl}`}}
                grid={{gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2}}
                dataSource={assets}
                renderItem={{
                    company: (asset, index) => {
                        return <List.Item key={asset.gvkey}>
                            <CompanyCard
                                asset={asset}
                                lockRatings={isDataLocked("#security-ratings")}
                                lockDividendYield={isDataLocked("#security-dividend-yield")}
                                id={`screener-asset-${index}`}
                            />
                        </List.Item>
                    },
                    fund: (asset, index) => {
                        return <List.Item key={"" + asset.gvkey + asset.iid}>
                            <FundCard
                                asset={asset}
                                lockRatings={isDataLocked("#security-ratings")}
                                lockDividendYield={isDataLocked("#security-dividend-yield")}
                                lockExpenseRatio={isDataLocked("#security-expense-ratio")}
                                lockAllocations={isDataLocked('#security-allocations')}
                                id={`screener-asset-${index}`}
                            />
                        </List.Item>
                    },
                    portfolio: (asset, index) => {
                        return <List.Item key={"" + asset._id}>
                            <PortfolioCard
                                asset={asset}
                                lockRatings={isDataLocked("#model-ratings")}
                                lockDividendYield={isDataLocked("#model-dividend-yield")}
                                lockExpenseRatio={isDataLocked("#model-expense-ratio")}
                                lockAllocations={isDataLocked("#model-allocations")}
                                id={`screener-asset-${index}`}
                            />
                        </List.Item>
                    },
                }[entityGroup]}
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={`No ${entityGroupLabelPl} found. Try changing the filters`}
                    />
                }}
            />
            {assets?.length > 0 && assets.length % limit === 0 && !loadingAssets
                &&
                <Flex justify="center">
                    <Button
                        size="large"
                        onClick={() => fetchMoreAssets()}
                        loading={loadingMoreAssets}
                        id={"screener-show-more"}
                    >
                        {`More ${entityGroupLabelPl}`}
                    </Button>
                </Flex>
            }
        </>
    }</>
}

export default AssetsList;
