/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {
    Flex,
    Tooltip,
    Space,
    Checkbox,
    Dropdown,
    message,
    Spin,
    Typography,
    theme, Divider, Button, Modal, Form, Input, Select, InputNumber,
} from 'antd';
import {
    AppstoreFilled,
    HeartFilled,
    LoadingOutlined,
} from "@ant-design/icons";
import {
    addToCollection,
    getAssetCollections,
    removeFromCollection
} from "@API/screener";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {capitalize} from "@global/Utils";
import "../AssetScreener.less";
import ReactGA from "react-ga4";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import {clientPortfolioClone, createFromPositions} from "@API/clientPortfolio";
import SimpleProgressModal from "@holistico/aux/SimpleProgressModal";
import ValueFormatter from "@global/ValueFormatter";
import {addNewPosition} from "@API/portfolio";
import {useAuth} from "@hooks/AuthHook";
import {LockModal} from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


const getAssetItemForCollection = (asset) => {
    if (['Stock', 'ADR'].includes(asset.security_type)) {
        return {
            entity: 'company', gvkey: asset.gvkey
        }
    }
    if (asset.security_type === 'ETF') {
        return {
            entity: 'etf', gvkey: asset.gvkey, iid: asset.iid
        }
    }
    if (asset.security_type === 'MF') {
        return {
            entity: 'fund', secid: asset.secid
        }
    }
    if (asset.security_type === 'Portfolio') {
        return {
            entity: 'portfolio', definition_id: asset.definition_id
        }
    }
}


const CustomCollectionButton = ({
                                    asset,
                                    collections,
                                    loading,
                                    onAddToCollection,
                                    onRemoveFromCollection,
                                    id
                                }) => {
    const {settings, entityGroupLabelSg} = useScreenerContext()
    const {token} = useToken();

    return <Tooltip
        title={`Click to choose ${asset.ticker ?? entityGroupLabelSg} collections`}
        mouseEnterDelay={0.5}
    >
        <Flex align={"center"} id={id}>
            <Dropdown
                menu={null}
                trigger={"click"}
                overlayClassName={'screener-asset-collections-popup'}
                overlayStyle={{}}
                dropdownRender={(menu) => (
                    <Spin spinning={loading} indicator={<LoadingOutlined
                        style={{color: "rgba(0,0,0,0)"}}/>}>
                        <div style={{
                            borderRadius: token.borderRadius,
                            background: "white",
                            boxShadow: token.boxShadow,
                            padding: `${token.padding}px ${token.paddingXL}px ${token.padding}px ${token.padding}px`
                        }}>
                            <Space direction={"vertical"}>
                                <Typography.Text strong>
                                    {`${asset.ticker ?? capitalize(entityGroupLabelSg)} collections`}
                                </Typography.Text>
                                <Divider style={{margin: 0}}/>
                                <Checkbox.Group
                                    style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                    value={collections}
                                >
                                    {settings.filters.collection.options.map((c) => {
                                        return <Checkbox
                                            key={c.value}
                                            value={c.value}
                                            onChange={(e) => {
                                                e.target.checked
                                                    ? onAddToCollection(c.value, c.label)
                                                    : onRemoveFromCollection(c.value, c.label)
                                            }}
                                        >
                                            {c.label}
                                        </Checkbox>
                                    })}
                                </Checkbox.Group>
                            </Space>
                        </div>
                    </Spin>)
                }
                placement={"bottomRight"}
            >
                <AppstoreFilled
                    style={{fontSize: 22, color: "rgb(150, 150, 150)"}}/>
            </Dropdown>
        </Flex>
    </Tooltip>
}


const FavoriteCollectionButton = ({
                                      collections,
                                      onAddToCollection,
                                      onRemoveFromCollection,
                                      id
                                  }) => {
    const {settings} = useScreenerContext()
    const fcId = settings.favorite_collection_id

    return <Tooltip
        title={collections.includes(fcId)
            ? "Remove from favorites"
            : "Add to favorites"
        }
        mouseEnterDelay={0.5}
    >
        <Flex justify={"center"} align={"center"}
              style={{height: 24, width: 32}} id={id}>
            <HeartFilled
                onClick={() => {
                    collections.includes(fcId)
                        ? onRemoveFromCollection(fcId, "Favorites")
                        : onAddToCollection(fcId, "Favorites");
                    ReactGA.event({
                        category: 'Asset card',
                        action: 'Click',
                        label: 'Add to favorites'
                    })
                }}
                className={collections.includes(fcId)
                    ? 'screener-favorite-icon selected'
                    : 'screener-favorite-icon'
                }
            />
        </Flex>
    </Tooltip>
}


const AddToPortfolioButton = ({asset, entityGroup, id}) => {
    const {getDataLimit} = useAuth();
    const {proposal, fetchProposal} = useHolisticoProposalContext();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);
    const [openLockModal, setOpenLockModal] = useState(false);
    const [progressMessage, setProgressMessage] = useState();
    const [addingInProgress, setAddingInProgress] = useState(false);
    const {token} = useToken();

    const portfolios = proposal?.current_portfolios ?? [];
    const accountOptions = useMemo(() => {
        if (entityGroup === "portfolio") {
            // For portfolio, new account is created by default
            return null;
        }

        let defaultValue = null;
        const options = portfolios.map(p => {
            const disabled = p.auxiliary_data?.source === "Plaid";
            if (!disabled) {
                defaultValue = p._id;
            }
            return {
                label: <Flex gap={"small"} align={"baseline"}>
                    {p.name}
                    {disabled && " (non-editable)"}
                </Flex>,
                value: p._id,
                disabled: disabled,
            }
        })

        if (options.every(option => option.disabled)) {
            // No editable accounts, need to create new account
            options.push({
                label: <Flex gap={"small"} align={"baseline"}>
                    Account {portfolios.length}
                    <Typography.Text type={"secondary"}>
                        (new)
                    </Typography.Text>
                </Flex>,
                value: "new",
            });
            defaultValue = "new";
        }
        return {options: options, defaultValue: defaultValue};
    }, [proposal])

    const accountsLimit = getDataLimit("#accounts-number");
    const newAccountsLocked = accountsLimit && proposal?.current_portfolios.length >= accountsLimit;
    const needNewAccount = entityGroup === "portfolio" || (accountOptions?.defaultValue === "new");
    const marketValue = proposal?.p_bucket_cur?.calculated_values?.market_value;

    const onSubmit = (values) => {
        setOpenModal(false);

        const progressMessageStart =  `Adding ${ValueFormatter.currencyCustom(values.market_value)} of ${asset.name}`;
        const callback = (response, error) => {
            setAddingInProgress(false);
            if (!error) {
                message.success(`${asset.ticker ?? asset.name} was successfully added to your portfolio!`);
                fetchProposal();
            } else {
                response && message.error(`Error while adding ${asset.ticker ?? asset.name} to your portfolio`);
            }
        }

        if (entityGroup === "portfolio") {
            setProgressMessage(progressMessageStart + " to your portfolio...");
            setAddingInProgress(true);
            clientPortfolioClone(proposal._id, asset.definition_id, "cur", false, values.market_value, callback);
        } else if (values.account !== "new") {
            const accountName = portfolios.filter(p => p._id === values.account)[0].name;
            setProgressMessage(progressMessageStart + ` to ${accountName} account...`);
            setAddingInProgress(true);
            const definition_id = values.account;
            const position = {...asset, market_value: values.market_value};
            addNewPosition(definition_id, position, callback);
        } else {
            const accountName = `Account ${portfolios.length}`;
            setProgressMessage(progressMessageStart + ` to ${accountName}...`);
            setAddingInProgress(true);
            const postData = {
                positions: [{...asset, market_value: values.market_value}],
                portfolio_name:  accountName,
                portfolio_type: "cur"
            }
            createFromPositions(proposal._id, postData, callback);
        }
        ReactGA.event({
            category: 'Asset card',
            action: 'Click',
            label: 'Add to portfolio, confirm'
        });
    }

    return <Tooltip title={"Add to portfolio"}>
        <Button
            type={"primary"}
            size={"small"}
            onClick={() => {
                if (needNewAccount && newAccountsLocked) {
                    setOpenLockModal(true);
                } else {
                    setOpenModal(true);
                }
                ReactGA.event({
                    category: 'Asset card',
                    action: 'Click',
                    label: 'Add to portfolio'
                });
            }}
            style={{marginLeft: token.marginXXS,}}
            id={id}
        >
            Get
        </Button>
        <Modal
            open={openModal}
            title={`Adding ${entityGroup === "portfolio" ? "model portfolio" : "asset"}`}
            onCancel={() => setOpenModal(false)}
            maskClosable={true}
            okButtonProps={{autoFocus: true, htmlType: 'submit'}}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    labelCol={{span: 7}}
                    wrapperCol={{span: 17}}
                    form={form}
                    initialValues={{
                        market_value:  entityGroup === "portfolio"
                            ? Math.round(0.1 * marketValue / 1000) * 1000
                            : Math.round(0.01 * marketValue / 100) * 100,
                        account: accountOptions?.defaultValue,
                    }}
                    clearOnDestroy
                    onFinish={(values) => onSubmit(values)}
                >
                    {dom}
                </Form>
            )}
        >
            <Typography.Paragraph type={"secondary"}>
                {asset.name} will be added
                to your portfolio
                {entityGroup === "portfolio" && " as a new account"}
            </Typography.Paragraph>
            {entityGroup !== "portfolio"
                && <Form.Item
                    name="account"
                    label="Add to account"
                    rules={[{
                        required: true,
                        message: 'Please choose account!'
                    }]}
                >
                    <Select options={accountOptions.options}/>
                </Form.Item>
            }
            <Form.Item
                name="market_value"
                label="Amount to add"
                rules={[{
                    required: true,
                    message: 'Please enter amount to add!'
                }]}
            >
                <InputNumber
                    formatter={(x) => ValueFormatter.currencyCustom(x, "$")}
                    style={{width: 120}}
                />
            </Form.Item>
        </Modal>
        <SimpleProgressModal
            description={progressMessage}
            open={addingInProgress}
            timeout={1500}
        />
        <LockModal
            customLabel={
                entityGroup === "portfolio"
                    ? <>
                        The model portfolio can be added only
                        to a new account of your portfolio.
                        But you've reached the limit of accounts
                        for your current subscription.
                        Upgrade your subscription to add the model.
                    </>
                    : <>
                        An asset can be added only to an editable account
                        of your portfolio. You have no editable accounts
                        and we cannot create new account since
                        you've reached the limit of accounts
                        for your current subscription.
                        Upgrade your subscription to add the asset.
                    </>
            }
            open={openLockModal}
            setOpen={() => setOpenLockModal(false)}
        />
    </Tooltip>
}


export const CardHeaderCollectionButtons = ({
                                                asset,
                                                loading,
                                                setLoading,
                                                twoLineHeader,
                                                style = {},
                                                id
                                            }) => {
    const {entityGroup, inProposalContext} = useScreenerContext();
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        setCollections(asset.collections.map(c => c._id));
    }, [asset])

    const onAddToCollection = (collection_id, collection_name) => {
        setLoading(true)
        const item = getAssetItemForCollection(asset);
        addToCollection(entityGroup, collection_id, item, (data, error) => {
            if (error) {
                message.error(`Something wrong happened while adding ${asset.ticker} to ${collection_name} collection!`);
            }
            getAssetCollections(entityGroup, item, (data, error) => {
                if (!error) {
                    setCollections(data.map(c => c._id));
                }
                setLoading(false)
                ReactGA.event({
                    category: 'Asset card',
                    action: 'Click',
                    label: 'Add to collection'
                });
            })
        })
    }

    const onRemoveFromCollection = (collection_id, collection_name) => {
        setLoading(true)
        const item = getAssetItemForCollection(asset)
        removeFromCollection(entityGroup, collection_id, item, (data, error) => {
            if (error) {
                message.error(`Something wrong happened while removing ${asset.ticker} from ${collection_name} collection!`);
            }
            getAssetCollections(entityGroup, item, (data, error) => {
                if (!error) {
                    setCollections(data.map(c => c._id))
                }
                setLoading(false)
            })
        })
    }

    return <Flex
        gap={"small"}
        align={"center"}
        style={twoLineHeader ? style : {marginTop: 2, ...style}}
    >
        <FavoriteCollectionButton
            collections={collections}
            onAddToCollection={onAddToCollection}
            onRemoveFromCollection={onRemoveFromCollection}
            id={`${id}-favorites`}
        />
        <CustomCollectionButton
            asset={asset}
            collections={collections}
            loading={loading}
            onAddToCollection={onAddToCollection}
            onRemoveFromCollection={onRemoveFromCollection}
            id={`${id}-custom-collections`}
        />
        {inProposalContext
            && <AddToPortfolioButton
                asset={asset}
                entityGroup={entityGroup}
                id={`${id}-get`}
            />
        }
    </Flex>
}


const CardHeader = ({
                        asset,
                        loading,
                        setLoading,
                        twoLine = false,
                        id
                    }) => {
    const textStyle = {
        fontSize: twoLine ? 20 : 24,
        fontWeight: 500,
        lineHeight: 1.2,
    }
    return <Flex justify={"space-between"} align={"flex-start"}>
        <Tooltip title={asset.name}>
            <Flex style={{width: "calc(100% - 120px)"}}>
                <span style={{...textStyle, color: "#e0ad60", marginRight: 12}}>
                    {asset.ticker}
                </span>
                <Typography.Paragraph
                    ellipsis={{rows: twoLine ? 2 : 1}}
                    style={{
                        ...textStyle,
                        marginBottom: 0,
                        height: twoLine ? 48 : "auto"
                    }}
                >
                    {asset.name}
                </Typography.Paragraph>
            </Flex>
        </Tooltip>
        <CardHeaderCollectionButtons
            asset={asset}
            loading={loading}
            setLoading={setLoading}
            twoLineHeader={twoLine}
            id={`${id}-buttons`}
        />
    </Flex>
}


export default CardHeader;
