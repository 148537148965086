import React, {useMemo} from "react";
import {Form, Input, InputNumber, Modal, Row} from "antd";

const LimitModal = ({
                        modalOpen,
                        setModalOpen,
                        flagName,
                        limitValue,
                        save,
                        limitDetails
                    }) => {
    const [form] = Form.useForm();

    const limitProperties = useMemo(() => {
        if (limitDetails.limit.type === "numeric")
            return {
                default_value: limitDetails.limit.default_value,
                lower_limit: limitDetails.limit.lower_limit,
                step: limitDetails.limit.step,
                upper_limit: limitDetails.limit.upper_limit
            }
        return {
            default_value: limitDetails.limit.numeric_settings.default_value,
            lower_limit: limitDetails.limit.numeric_settings.lower_limit,
            step: limitDetails.limit.numeric_settings.step,
            upper_limit: limitDetails.limit.numeric_settings.upper_limit
        }
    }, [limitDetails])

    const handleCancel = () => {
        form.resetFields();
        setModalOpen(false);
    }

    return (
        (<Modal title={"Set limit for " + flagName + " flag"}
               open={modalOpen}
               onCancel={handleCancel}
               onOk={form.submit}
               okText="Update"
               className={'form-modal'}
        >
            <Form labelCol={{span: 4}}
                  wrapperCol={{span: 20}}
                  form={form}
                  autoComplete="off"
                  scrollToFirstError
                  layout="horizontal"
                  validateTrigger={'onSubmit'}
                  name="limit_flag"
                  onFinish={(values) => {
                      save(values)
                  }}
                  requiredMark={false}
            >
                <Form.Item
                    name="limit_value"
                    extra={<>
                        <Row>{limitDetails.limit.description}</Row>
                        <Row>Bounds of limit value: from {limitProperties.lower_limit} to {limitProperties.upper_limit}</Row>
                    </>}
                    label="Limit"
                    initialValue={limitValue ? limitValue : limitProperties.default_value}
                    rules={[{
                        required: true,
                        message: 'Missing limit',
                    }, {
                        min: limitProperties.lower_limit,
                        max: limitProperties.upper_limit,
                        type: 'number',
                        message: `Bounds of limit value: from ${limitProperties.lower_limit} to ${limitProperties.upper_limit}`
                    }]
                    }>
                        <InputNumber
                            formatter={limitDetails.limit.units === "%" ?
                                value => (value + "%") :
                                value => value
                            }
                            min={limitProperties.lower_limit}
                            max={limitProperties.upper_limit}
                            step={limitProperties.step}
                        />
                </Form.Item>
                <Form.Item
                    name="comment"
                    label="Comment"
                    help={false}
                    className={'hide-errors'}
                    rules={[
                        {
                            required: true,
                            type: 'string',
                            message: 'Missing comment',
                        },
                    ]}
                >
                    <Input.TextArea showCount maxLength={300}/>
                </Form.Item>
            </Form>
        </Modal>)
    );
}

export default LimitModal
