import React from "react";
import dayjs from "dayjs";
import {
    Button,
    ConfigProvider,
    Flex,
    Modal,
    Popover,
    theme, Typography,
} from "antd";
import {LockFilled} from "@ant-design/icons";
import Constants from "@global/Constants";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


export const getLockMessageText = (
    label = null,
    customLabel = null,
    stuff = null,
    plural = false,
) => {
    if (customLabel) {
        return customLabel;
    } else {
        return `${label} ${stuff ?? ""} ${plural ? "are" : "is"}`
            + ` not available for your current subscription.`
    }
}


export const getRequestLockParams = (limitMessage, lockMessage, lockedUntil, resettable=true) => {
    let message;
    let type;

    if (lockedUntil) {
        message = <>
            <span>
                {limitMessage}
                {resettable &&
                    ` For your subscription, this option will become available`
                    + ` again on ${dayjs(lockedUntil).format("MMMM D, YYYY")}`
                    + ` at ${dayjs(lockedUntil).format("hh:mmA")}.`
                    + ` Alternatively, you can upgrade your subscription.`
                }
                {!resettable &&
                    ` Upgrade your subscription to reset the limit.`
                }
            </span>

        </>
        type = "limit";
    } else {
        message = lockMessage;
        type = "lock";
    }

    return {message: message, type: type}
}


export const RequestLockTextPlaceholder = ({
                                               limitMessage,
                                               lockMessage,
                                               lockedUntil
                                           }) => {
    const {currentSubscription} = useAuth();
    const resettable = currentSubscription?.ui_product_key !== "free";
    const params = getRequestLockParams(limitMessage, lockMessage, lockedUntil, resettable);

    return <LockTooltip locked={params.type === "lock"}
                        customLabel={params.message}>
        {params.message}
    </LockTooltip>
}


export const RequestLockTooltip = ({
                                       limitMessage,
                                       lockMessage,
                                       lockedUntil,
                                       locked = true,
                                       children
                                   }) => {
    const {currentSubscription} = useAuth();
    const resettable = currentSubscription?.ui_product_key !== "free";
    const params = getRequestLockParams(limitMessage, lockMessage, lockedUntil, resettable);

    return <LockTooltip locked={locked} customLabel={params.message}>
        {children}
    </LockTooltip>
}


export const RequestLockModal = ({
                                     limitMessage,
                                     lockMessage,
                                     lockedUntil,
                                     open,
                                     setOpen,
                                 }) => {
    const {currentSubscription} = useAuth();
    const resettable = currentSubscription?.ui_product_key !== "free";
    const params = getRequestLockParams(limitMessage, lockMessage, lockedUntil, resettable);

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={null}
        footer={null}
        width={400}
    >
        <LockMessage
            customLabel={params.message}
            // hideButton={params.type === "limit"}
        />
    </Modal>
}


export const LockMessage = ({
                                label,
                                customLabel = null,
                                stuff = null,
                                plural = false,
                                hideIcon = false,
                                hideButton = false,
                            }) => {
    const {token} = useToken();

    return <Flex gap={"small"} align={"flex-start"} style={{maxWidth: 300}}>
        {!hideIcon
            && <LockFilled style={{fontSize: token.fontSizeHeading1}}/>
        }
        <Flex vertical gap={"middle"}>
            {getLockMessageText(label, customLabel, stuff, plural)}
            {!hideButton
                && <a target={"_blank"}
                      href={`/${Constants.RETAIL_URL}/profile/subscription`}>
                    <Button type={"primary"}>
                        Upgrade subscription
                    </Button>
                </a>
            }
        </Flex>
    </Flex>
}


export const LockModal = ({
                              label,
                              customLabel = null,
                              stuff = null,
                              plural = false,
                              hideIcon = false,
                              hideButton = false,
                              open,
                              setOpen,
                          }) => {
    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={null}
        footer={null}
        width={400}
    >
        <LockMessage
            label={label}
            customLabel={customLabel}
            stuff={stuff}
            plural={plural}
            hideIcon={hideIcon}
            hideButton={hideButton}
        />
    </Modal>
}


export const LockPlaceholder = ({
                                    label,
                                    customLabel = null,
                                    stuff = null,
                                    plural = false,
                                    size = 100,
                                    align = "center",
                                    justify = "center",
                                    style = {}
                                }) => {
    const {token} = useToken();

    let _size = 100;
    if (typeof size === "string") {
        if (size === "large") _size = 100;
        if (size === "medium") _size = 60;
        if (size === "small") _size = 20;
    }

    return <Flex align={align} justify={justify} style={{...style}}>
        <LockTooltip
            label={label}
            customLabel={customLabel}
            stuff={stuff}
            plural={plural}
        >
            <LockFilled style={{
                color: token.colorFill,
                fontSize: _size,
            }}/>
        </LockTooltip>
    </Flex>
}


const LockTooltip = ({
                         label,
                         customLabel = null,
                         stuff = null,
                         plural = false,
                         locked = true,
                         blur = false,
                         hideButton = false,
                         hideIcon = false,
                         style = {},
                         children
                     }) => {
    const {token} = useToken();

    return <ConfigProvider theme={{
        components: {
            Popover: {
                colorBgElevated: token.colorBgSpotlight,
                colorText: "white",
            }
        }
    }}>
        <Popover content={
            locked
                ? <LockMessage
                    label={label}
                    customLabel={customLabel}
                    stuff={stuff}
                    plural={plural}
                    hideIcon={hideIcon}
                    hideButton={hideButton}
                />
                : null
        }>
            {locked && blur
                ? <div style={{
                    position: "relative",
                    filter: 'blur(8px)',
                    userSelect: 'none',
                    ...style
                }}>
                    {children}
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%"
                    }}/>
                </div>
                : <div style={style}>
                    {children}
                </div>
            }
        </Popover>
    </ConfigProvider>
}


export default LockTooltip;
