import axiosInstance from "../APIClient";

let cancel

const getModelsListAsync = async () => {
    let url = `/portfolio/model?full=false`
    return await axiosInstance.get(url)
}

const getProposalsAsync = async (
    abortSignal,
    include_own_usual = true,
    include_shared_usual = true,
    include_own_retail = false,
    include_shared_retail = true,
    sort = '_id',
    limit = 10
) => {
    let url = "/client";
    let params = {
        include_own_usual: include_own_usual,
        include_shared_usual: include_shared_usual,
        include_own_retail: include_own_retail,
        include_shared_retail: include_shared_retail,
        sort: sort,
        limit: limit
    };
    return await axiosInstance.get(url, {params: params, signal: abortSignal});
}

const getSectorPrognosisAsync = async (limit, abortSignal) => {
    let url = `/dashboard/sector_prognosis`
    return await axiosInstance.get(url, {signal: abortSignal})
}

const getNewsAsync = async (abortSignal) => {
    let url = `/dashboard/news`
    return await axiosInstance.get(url, {signal: abortSignal})
}

const getEventsAsync = async (abortSignal) => {
    let url = `/dashboard/events`
    return await axiosInstance.get(url, {signal: abortSignal})
}

const getChampionsAsync = async (entityGroup = "company", orderFactor = "sscore_plus", limit = 16) => {
    //let url = `screener/${entityGroup}/assets`
    let url = `screener/${entityGroup}/assets`
    let params = {
        order_by: `${orderFactor}`,
        limit: limit
    }
    return await axiosInstance.get(url, {params: params})
}

const getStockIdeasAsync = async (limit = 10) => {
    let url = `dashboard/stock-ideas`
    let params = {limit: limit}
    return await axiosInstance.get(url, {params: params});
}

const getEtfIdeasAsync = async (limit = 10) => {
    let url = `dashboard/etf-ideas`
    let params = {limit: limit}
    return await axiosInstance.get(url, {params: params});
}

const getModelIdeasAsync = async (limit = 10) => {
    let url = `dashboard/model-ideas`
    let params = {limit: limit}
    return await axiosInstance.get(url, {params: params});
}

const getTreasuryYieldsAsync = async () => {
    let url = `/dashboard/treasury_yields`
    return await axiosInstance.get(url)
}

const getCustomMacro = async (columns_array, from_date = "1976-01-31") => {
    let params = {
        from_date: from_date,
        columns: columns_array.join(',')
    }
    let url = `/dashboard/macro`
    return await axiosInstance.get(url, {params: params})
}

const getMacroStats = async (columns_array) => {
    let params = {
        columns: columns_array.join(',')
    }
    let url = `/dashboard/macro-stats`
    return await axiosInstance.get(url, {params: params})
}

const getMacroHistorySeparate = async (columns_array, from_date = "1976-01-31") => {
    let params = {
        from_date: from_date,
        columns: columns_array.join(',')
    }
    let url = `/dashboard/macro-history-separate`
    return await axiosInstance.get(url, {params: params})
}

const getMarketMonitorComment = async () => {
    let url = `/dashboard/market-monitor-comment`
    return await axiosInstance.get(url)
}

const getFactorPerformance = async () => {
    let url = `/dashboard/factor-performance`
    return await axiosInstance.get(url)
}

const recognition = async (role) => {
    let params = {
        role: role
    }
    let url = `/auth/recognition`
    return await axiosInstance.get(url, {params: params})
}

export {
    getProposalsAsync,
    recognition,
    getCustomMacro,
    getMacroHistorySeparate,
    getMacroStats,
    getMarketMonitorComment,
    getFactorPerformance,
    getTreasuryYieldsAsync,
    getEventsAsync,
    getModelsListAsync,
    getNewsAsync,
    getSectorPrognosisAsync,
    getChampionsAsync,
    getStockIdeasAsync,
    getEtfIdeasAsync,
    getModelIdeasAsync,
    cancel
}
