import React, {useState} from "react";
import {Card, ConfigProvider, Table, theme, Typography} from "antd";
import {useRequest, useUpdateEffect} from "ahooks";
import {is_null} from "@global/ValueFormatter";
import {getFactorPerformance} from "@API/ahooks/dashboard";
import ChartStyles from "@global/ChartStyles";
import TourButton from "@components/retail/cards/TourButton";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


const cellRenderer = (value) => {
    if (is_null(value)) {
        return '-'
    }

    return value.toFixed(1);
}


const onCell = (record, column) => {
    const value = record[column];

    if (is_null(value)) {
        return {};
    }

    return {
        style: {
            background: ChartStyles.toColor(
                value, -2, 2,
                '#f5222d', '#FFFFFF', '#8eea9a'
            )
        }
    };
}


const tableColumns = [
    {
        title: 'Period',
        dataIndex: 'Period',
        key: 'Period',
        align: "left",
        render: (value) => (<Typography.Text ellipsis>{value}</Typography.Text>)
    },
    ...['Value', 'Yield', 'Growth', 'Momentum', 'Profitability', 'Safety'].map(column => {
        return {
            title: <div style={{
                wordBreak: "break-word",
                lineHeight: 1
            }}>
                {column}
            </div>,
            dataIndex: column,
            key: column,
            align: "center",
            width: "14%",
            render: (value) => cellRenderer(value),
            onCell: (record) => onCell(record, column),
        }
    })
];


const FactorPerformanceTable = ({id}) => {
    const [data, setData] = useState([]);

    const {
        data: response,
        loading,
        error
    } = useRequest(() => getFactorPerformance());

    useUpdateEffect(() => {
        if (error) {
            console.error(error.message)
        } else {
            console.error('Error while loading factors performance')
        }
    }, [error])

    useUpdateEffect(() => {
        console.debug("Factors performance loaded", response.data);
        setData(response.data ?? []);
    }, [response])

    return <Table
        pagination={{
            hideOnSinglePage: true,
            pageSize: 20,
        }}
        dataSource={data}
        columns={tableColumns}
        rowKey={'Period'}
        id={id}
    />
}


const FactorPerformanceCardTour = ({}) => {
    const steps = [
        {
            title: <>
                This table shows the alpha of basic factor groups
                during the last 12 months. It shows which factor worked better
                and can be used to monitor current market regime. Positive
                values mean that companies with good factor values
                performed better than the market.
            </>,
            target: "factor-performance-card-table",
        },
    ];

    return <TourButton steps={steps}/>
}


const FactorPerformanceCard = ({}) => {
    return <Card hoverable style={{
        overflow: "hidden",
        cursor: "default",
        height: "100%"
    }}>
        <CardHeader
            title={"Factors performance"}
            tour={<FactorPerformanceCardTour/>}
            id={"factor-performance-card-header"}
        />
        <ConfigProvider theme={{
            token: {
                fontSize: 12,
            },
            components: {
                Table: {
                    cellPaddingBlock: 2,
                    cellPaddingInline: 4,
                }
            }
        }}>
            <FactorPerformanceTable id={"factor-performance-card-table"}/>
        </ConfigProvider>
    </Card>
}

export default FactorPerformanceCard;
