import React, {useState} from "react";
import {Button, Card, Flex, Spin, theme, Tooltip, Typography} from "antd";
import {is_null} from "@global/ValueFormatter";
import {LockFilled, QuestionCircleFilled} from "@ant-design/icons";
import LockTooltip from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


export const StatsCardDescription = ({children}) => {
    const {token} = useToken();

    return <Typography.Paragraph style={{
        color: token.colorTextDescription,
        fontSize: token.fontSizeXS,
    }}>
        {children}
    </Typography.Paragraph>
}


export const StatsCardButton = ({onClick=null, icon=null, id, children}) => {
    const {token} = useToken();

    return <Button
        onClick={onClick}
        type={"link"}
        icon={icon}
        style={{fontSize: token.fontSizeSM, padding: 0}}
        id={id}
    >
        {children}
    </Button>
}


const StatsCard = ({title, value, loading=false, locked=false, style={}, tooltip=null, id, children}) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Card
        type={"inner"}
        style={{
            background: hover ? token.colorBgHoverBlue : token.colorBgGrey,
            position: "relative",
            borderColor: token.colorBorder,
            ...style
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        id={id}
    >
        <Spin spinning={loading}>
            <Flex vertical align={"flex-start"} style={{width: "100%"}}>
                <LockTooltip label={title} stuff={"data"} locked={locked}>
                    <span style={{
                        fontSize: token.fontSizeHeading2,
                        lineHeight: 1,
                        color: locked ? token.colorTextDescription : token.colorStats,
                        width: "100%"
                    }}>
                        {locked ? <LockFilled/> : (is_null(value) ? "-" : value)}
                    </span>
                </LockTooltip>
                <span style={{
                    marginTop: token.paddingSM,
                    fontSize: token.fontSizeXS,
                    textTransform: "uppercase",
                    lineHeight: 1.2,
                    letterSpacing: 1,
                }}>
                    {title}
                </span>
                {children}
            </Flex>
        </Spin>
        {tooltip
            && <Tooltip title={tooltip}>
                <QuestionCircleFilled
                    style={{
                        fontSize: token.fontSize,
                        color: token.colorTextDescription,
                        cursor: "pointer",
                        position: "absolute",
                        right: token.marginXXS,
                        top: token.marginXXS
                    }}
                />
            </Tooltip>
        }
    </Card>
}


export default StatsCard;
