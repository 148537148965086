import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {AuthWrapper} from "@hooks/AuthHook";
import "./index.css"

import AppRouter from "@app/AppRouter";


if (module.hot) {
    module.hot.accept()
}


if (process.env.REACT_APP_DEBUG === "0") {
    let executed_console;
    if (console) {
        executed_console = console;
    } else if (window.console) {
        executed_console = window.console;
    }
    let methods = ["log", "debug", "warn", "table"];
    for (let i = 0; i < methods.length; i++) {
        executed_console[methods[i]] = function () {
        };
    }
}

const mode = process.env.REACT_APP_UI_VERSION

const container = document.getElementById('root');
const root = createRoot(container);

function AppWithCallbackAfterRender() {
    useEffect(() => {
        console.log('rendered');
    });

    return <AuthWrapper>
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    </AuthWrapper>
}


root.render(<AppWithCallbackAfterRender/>)
