import React, {useEffect, useMemo} from "react";
import {
    Alert,
    Flex,
    theme,
    Typography
} from "antd";
import {WalletFilled} from "@ant-design/icons";
import CardHeader from "@components/retail/cards/CardHeader";
import {useAuth} from "@hooks/AuthHook";
import SubscriptionOptions, {
    SubscriptionButton
} from "@components/retail/profile/SubscriptionOptions";
import {
    RETAIL_SUBSCRIPTION_PLANS_DICT
} from "@components/retail/profile/subscriptionPlans";
import {useRequest, useUpdateEffect} from "ahooks";
import {start_pay_session} from "@API/ahooks/subscriptions";
import {useLocation, useSearchParams} from "react-router-dom";
import {useTnc} from "@hooks/TncContext";
import {useAdsTrack} from "@hooks/AdsConversion";


const {useToken} = theme;


const CurrentSubscription = ({}) => {
    const {user, accessDetails} = useAuth();
    const {token} = useToken();

    const subscriptionInfo = useMemo(() => {
        let server_info = {}
        const current_subscription = user.current_subscription ? user.current_subscription : {plan: {}};
        const stripe_info = user.stripe_info
        let trial = false
        if (current_subscription.status === 'trialing') {
            trial = true;
        }

        let ui_product_key = current_subscription['ui_product_key']
        const ui_plan = RETAIL_SUBSCRIPTION_PLANS_DICT[ui_product_key]
        const end_date = new Date(current_subscription.current_period_end * 1000);
        const formatted_date = end_date.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });

        server_info = {
            ui_product_key: ui_product_key,
            trial: trial,
            status: current_subscription.status,
            balance: stripe_info.balance,
            until: formatted_date,
            title: current_subscription.plan.nickname
        }

        return server_info;
    }, [user]);
    console.log("subscriptionInfo", subscriptionInfo)

    return <Flex vertical gap={"middle"}>
        <Flex gap={"small"} align={"baseline"} wrap={"wrap"}>
            <Typography.Text>
                Current plan:
            </Typography.Text>
            <Typography.Text strong style={{marginRight: token.margin}}>
                {subscriptionInfo['title']}
            </Typography.Text>
            {subscriptionInfo?.ui_product_key !== "free"
                && <SubscriptionButton
                    buttonText={"Manage"}
                    priceId={null}
                    size={"small"}
                    type={"primary"}
                />
            }
        </Flex>
        {!accessDetails?.test_settings_enabled
            && !accessDetails.subscription_expired
            && subscriptionInfo?.ui_product_key === "free"
            && <Alert
                message={<>
                    Free subscription will expire on {subscriptionInfo.until}.
                    Upgrade your subscription to continue using Holistico after this date.
                </>}
                type="warning"
                showIcon
                style={{width: "fit-content"}}
            />
        }
        {!accessDetails?.test_settings_enabled
            && accessDetails.subscription_expired
            && <Alert
                message={<>
                    Your subscription has expired.
                    Upgrade your subscription to get access to Holistico.
                </>}
                type="error"
                showIcon
                style={{width: "fit-content"}}
            />
        }
        {accessDetails?.test_settings_enabled
            && <Alert
                message={<>
                    You are in the test mode for {" "}
                    <span style={{textTransform: "capitalize", fontWeight: "bold"}}>
                        {accessDetails?.test_settings_subscription_key}
                    </span>
                    {" "} subscription
                </>}
                type="warning"
                showIcon
                style={{width: "fit-content"}}
            />
        }
    </Flex>
}


const Subscription = ({}) => {
    const {user} = useAuth();
    const track = useAdsTrack();
    const {needToSign} = useTnc();
    const [searchParams, setSearchParams] = useSearchParams();
    const {token} = useToken();

    const {run} = useRequest(start_pay_session, {
        manual: true,
        onSuccess: (result) => {
            console.log('Created the session:', result);
            track('AddToCart', {email_address: user.email});
            window.location.href = result.data.url;
        },
        onError: (error) => {
            console.error('Error creating payment session:', error);
        },
    });

    // console.log("Subscription render", searchParams?.get('subscribe-to'), needToSign)
    useEffect(() => {
        if (searchParams.get('success') === 'true') {
            track('Purchase')
        }
        // This effect is used to subscribe from landing page after auth.
        const priceId = searchParams.get('subscribe-to');
        // console.log("Subscription useEffect", searchParams?.get('subscribe-to'), needToSign, priceId && needToSign === false)
        if (priceId && needToSign === false) {
            const finalRedirect = window.location.href.slice(0, window.location.href.indexOf('?'));
            run(priceId, finalRedirect)
        }


    }, [searchParams, needToSign])

    return <>
        <div style={{padding: token.paddingLG}}>
            <CardHeader title={"Subscription plans"} icon={<WalletFilled/>}/>
            <CurrentSubscription/>
        </div>
        <SubscriptionOptions currentPriceId={user.current_subscription?.plan?.id}/>
    </>
}


export default Subscription;
