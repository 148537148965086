/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Flex, List, message,
    Progress,
    Row, Space,
    Spin, Tabs, theme, Tooltip, Typography
} from 'antd';
import {blue, green, grey, red} from '@ant-design/colors';
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import CardHeader from "@components/screener/card/CardHeader";
import StatsCard from "@components/screener/card/StatsCard";
import DescriptiveInfo from "@components/screener/card/DescriptiveInfo";
import Localizator from "@global/Localizator";
import {
    CompassFilled, DownOutlined,
    LockFilled,
    PushpinFilled, RightCircleFilled, RightOutlined, UpOutlined,
    VerticalAlignMiddleOutlined
} from "@ant-design/icons";
import LockTooltip from "@components/retail/cards/LockTooltip";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {useRequest} from "ahooks";
import {getAiThoughts} from "@API/ahooks/proposal";
import {describeWithAi, getExistingAiComment} from "@API/ahooks/asset";
import parse from "html-react-parser";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";


const {useToken} = theme;


export const getRatingColor = (value) => {
    return value > 60 ? green[3] : (value <= 20 ? red[3] : blue[3])
}


const ScoreStatsCompact = ({title, value, locked = false, rank = false}) => {
    const {token} = useToken();

    let rating_value = value
    if (rank && value) {
        rating_value = (6 - value) * 20 - 10
    }

    rating_value = ValueFormatter.score(rating_value)

    return <Flex align={"center"}>
        {!locked && <Progress
            percent={rating_value ? rating_value + 10 : 0}
            steps={5}
            showInfo={false}
            strokeColor={getRatingColor(rating_value)}
            size={[3, 12]}
        />}
        <span style={{
            fontSize: 16,
            fontWeight: 500,
            marginLeft: 8,
            width: 32,
            minWidth: 32,
        }}>
            {locked
                ? <LockTooltip label={title} stuff={"data"}>
                    <LockFilled style={{color: token.colorTextDescription}}/>
                </LockTooltip>
                : (is_null(value) ? "-" : value)
            }
        </span>
        <span style={{
            fontSize: 14,
            color: grey[4],
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }}>
                {title}
            </span>
    </Flex>
}


const MainPanel = ({
                       asset,
                       lockRatings = false,
                       lockDividendYield = false,
                   }) => {
    return <Row gutter={[16, 16]} style={{marginBottom: 20, minHeight: 90}}>
        <Col span={6}>
            <StatsCard
                title={Localizator.t("factor.sscore_plus")}
                value={asset.sscore_plus}
                progress={asset.sscore_plus ? asset.sscore_plus + 10 : 0}
                progressColor={getRatingColor(asset.sscore_plus)}
                style={{minHeight: 90}}
                wrapTitle={true}
                locked={lockRatings}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={"Dividend Yield"}
                value={asset.div_yield ? ValueFormatter.int_percent(asset.div_yield) : '-'}
                style={{minHeight: 90}}
                wrapTitle={true}
                locked={lockDividendYield}
            />
        </Col>
        <Col span={12}>
            <DescriptiveInfo
                title1={
                    (asset.size ?? "")
                    + (asset.vbg_style ? " " + asset.vbg_style : "")
                    + (asset.country ? ", " + asset.country : "")
                }
                title2={asset.sector ?? "-"}
                title3={asset.industry ?? "-"}
            />
        </Col>
    </Row>
}


const RatingsPanel = ({asset, lockRatings}) => {
    return <Row gutter={[16, 16]} style={{marginBottom: 24}}>
        <Col span={12}>
            <ScoreStatsCompact
                title={"Growth"}
                value={asset.sscore_plus_growth}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"Momentum"}
                value={asset.sscore_plus_momentum}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"Profitability"}
                value={asset.sscore_plus_profitability}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"Safety"}
                value={asset.sscore_plus_safety}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"Value"}
                value={asset.sscore_plus_value}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"Yield"}
                value={asset.sscore_plus_yield}
                locked={lockRatings}
            />
        </Col>
        <Col span={12}>
            <ScoreStatsCompact
                title={"1Y Macro Score"}
                value={asset.macro_score_1yr}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"1Q Macro Score"}
                value={asset.macro_score_1q}
                locked={lockRatings}
            />
            <ScoreStatsCompact
                title={"ZScore"}
                value={asset.z_score}
                locked={lockRatings}
            />
            {/*<div style={{marginTop: 12}}/>*/}
            {/*<ScoreStatsCompact*/}
            {/*    title={"Comp. Rating, IBD"}*/}
            {/*    value={asset.comp_rating_ibd}*/}
            {/*/>*/}
            {/*<ScoreStatsCompact*/}
            {/*    title={"CM Rank, ER"}*/}
            {/*    value={asset.cm_rank_er}*/}
            {/*    rank*/}
            {/*/>*/}
            {/*<ScoreStatsCompact*/}
            {/*    title={"Timeliness Rank, VL"}*/}
            {/*    value={asset.timeliness_rank_vl}*/}
            {/*    rank*/}
            {/*/>*/}
            {/*<ScoreStatsCompact*/}
            {/*    title={"Zacks Rank"}*/}
            {/*    value={asset.zacks_rank_zks}*/}
            {/*    rank*/}
            {/*/>*/}
        </Col>
    </Row>
}


const MetricsPanel = ({asset}) => {
    return <Descriptions
        bordered
        items={[
            {
                key: 'mkval',
                label: 'Market Cap.',
                children: is_null(asset.mkval)
                    ? "-" : ValueFormatter.compactCurrency(1000000 * asset.mkval),
                span: 2
            },
            {
                key: 'price_to_earnings',
                label: 'P/E',
                children: is_null(asset.price_to_earnings)
                    ? "-" : ValueFormatter.round(asset.price_to_earnings, 1)
            },
            {
                key: 'sales_growth_1yr',
                label: 'Sales Growth 1Y',
                children: is_null(asset.sales_growth_1yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.sales_growth_1yr / 100)
            },
            {
                key: 'price_to_book',
                label: 'P/B',
                children: is_null(asset.price_to_book)
                    ? "-" : ValueFormatter.round(asset.price_to_book, 1)
            },
            {
                key: 'sales_growth_5yr',
                label: 'Sales Growth 5Y',
                children: is_null(asset.sales_growth_5yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.sales_growth_5yr / 100)
            },
            {
                key: 'roa',
                label: 'ROA',
                children: is_null(asset.roa)
                    ? "-" : ValueFormatter.round(asset.roa, 1)
            },
            {
                key: 'idiosyncratic_risk_mse',
                label: 'Idiosyncratic Risk (MSE)',
                children: is_null(asset.idiosyncratic_risk_mse)
                    ? "-" : ValueFormatter.round(asset.idiosyncratic_risk_mse, 1)
            },
            {
                key: 'roe',
                label: 'ROE',
                children: is_null(asset.roe)
                    ? "-" : ValueFormatter.round(asset.roe, 1)
            },
            {
                key: 'idiosyncratic_risk_r2',
                label: 'Idiosyncratic Risk (R2)',
                children: is_null(asset.idiosyncratic_risk_r2)
                    ? "-" : ValueFormatter.round(asset.idiosyncratic_risk_r2, 1)
            },
            {
                key: 'epe_growth',
                label: 'ePE / Growth',
                children: is_null(asset.epe_growth)
                    ? "-" : ValueFormatter.round(asset.epe_growth, 2)
            },
            {
                key: 'total_return_1yr',
                label: '1Y Return',
                children: is_null(asset.total_return_1yr)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1yr / 100)
            },
            {
                key: 'total_return_ytd',
                label: 'YTD Return',
                children: is_null(asset.total_return_ytd)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_ytd / 100)
            },
            {
                key: 'total_return_1m',
                label: '1M Return',
                children: is_null(asset.total_return_1m)
                    ? "-" : ValueFormatter.oneDigitPercent(asset.total_return_1m / 100)
            },
        ]}
        size={"small"}
        column={2}
        contentStyle={{fontSize: 14, padding: "4px 8px"}}
        labelStyle={{fontSize: 14, padding: "4px 8px"}}
    />
}


export const PeersPanel = ({asset}) => {
    const {setSearchParams, inScreener} = useScreenerContext();
    const {token} = useToken();

    return <List
        dataSource={asset?.peers?.filter((_, idx) => (idx < 7))}
        renderItem={(item, idx) => {
            return <List.Item key={idx} style={{
                paddingTop: token.paddingXXS,
                paddingBottom: token.paddingXXS,
                fontSize: token.fontSizeSM,
                display: "flex",
                justifyContent: "flex-start",
            }}>
                <Typography.Text strong ellipsis style={{
                    minWidth: 80,
                    maxWidth: 80,
                    fontSize: token.fontSizeSM
                }}>
                    {item.ticker}
                </Typography.Text>
                <Typography.Text ellipsis style={{
                    fontSize: token.fontSizeSM
                }}>
                    {item.name}
                </Typography.Text>
                {inScreener
                    && <Tooltip title={"Compare"} placement={"left"}>
                        <Button
                            size={"small"}
                            type={"text"}
                            onClick={() => {
                                setSearchParams(prev => {
                                    for (const key of [...prev.keys()]) {
                                        prev.delete(key);
                                    }
                                    prev.append("search-pinned", asset.name);
                                    prev.append("search-pinned", item.name);
                                    return prev;
                                })
                            }}
                            // icon={<RightOutlined/>}
                            style={{
                                marginLeft: "auto",
                                color: token.colorTextDescription
                            }}
                        >
                            {"<>"}
                        </Button>
                    </Tooltip>
                }
            </List.Item>
        }}
    />
}


export const AssetAiComment = ({asset}) => {
    const {token} = useToken();
    const [comment, setComment] = useState(asset.aiComment);
    const [expanded, setExpanded] = useState(false);

    const {
        runAsync: createComment,
        loading: creating,
    } = useRequest(describeWithAi, {manual: true});

    const {
        runAsync: getExistingComment,
        loading: loading,
    } = useRequest(getExistingAiComment, {manual: true})

    const onClick = () => {
        createComment(asset).then((result) => {
            if (result) {
                setComment(result.data);
                setExpanded(true);
            }
        }).catch((error) => {
            console.error(error);
            message.error("Failed to create Holi's comment!");
        }).finally()
    }

    useEffect(() => {
        getExistingComment(asset).then((result) => {
            if (result) {
                setComment(result.data);
            }
        }).catch((error) => {
            console.error(error);
            message.error("Failed to load Holi's comment!");
        }).finally()
    }, [])

    return <Spin
        spinning={loading || creating}
        tip={loading ? "" : "Holi is thinking..."}
    >
        {comment
            ? <>
                <div style={{
                    maxHeight: expanded ? "none" : 190,
                    overflow: "scroll",
                    marginRight: -token.margin,
                    paddingRight: token.margin
                }}>
                    {comment.split("\n\n").map((par, idx) => {
                        return <Typography.Paragraph key={idx}>
                            {parse(par)}
                        </Typography.Paragraph>
                    })}
                </div>
                <Button
                    onClick={() => setExpanded(prev => !prev)}
                    type={"link"}
                    size={"small"}
                    icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                    style={{paddingLeft: 0, marginTop: token.margin}}
                >
                    {expanded ? "Hide" : "Expand"}
                </Button>
            </>
            : <NoDataPlaceholder description={"Holi's comment not found"} style={{
                marginBlockStart: 0,
            }}>
                <Button type={"primary"} onClick={onClick}>
                    Request comment
                </Button>
            </NoDataPlaceholder>
        }
    </Spin>
}


const CompanyCardTooltip = ({asset}) => {
    const [loading, setLoading] = useState(false)

    return <Card style={{borderColor: grey[4]}}>
        <Spin spinning={loading}>
            <Space direction={"vertical"}>
                <CardHeader asset={asset} loading={loading}
                            setLoading={setLoading}/>
                <MainPanel asset={asset}/>
                <RatingsPanel asset={asset}/>
            </Space>
        </Spin>
    </Card>
}


const CompanyCard = ({
                         asset,
                         lockRatings = false,
                         lockDividendYield = false,
                         id
                     }) => {
    const {inScreener} = useScreenerContext();
    const [loading, setLoading] = useState(false);
    const {token} = useToken();
    const tabChildStyle = {
        minHeight: 280,
        background: token.colorBgGrey,
        margin: `-${token.margin}px -${token.marginLG}px -${token.marginLG}px`,
        padding: token.paddingLG,
    }

    return <Card hoverable={inScreener} style={{
        borderColor: "#e3e3e3",
        overflow: "hidden",
        cursor: "default"
    }}>
        <Spin spinning={loading}>
            <CardHeader asset={asset} loading={loading}
                        setLoading={setLoading} id={`${id}-header`}/>
            <Divider style={{margin: "8px 0 16px 0"}}/>
            <MainPanel
                asset={asset}
                lockRatings={lockRatings}
                lockDividendYield={lockDividendYield}
            />
            <RatingsPanel
                asset={asset}
                lockRatings={lockRatings}
            />
            <Tabs
                defaultActiveKey={"factors"}
                items={[
                    {
                        key: "factors",
                        label: "Fundamentals",
                        children: <div style={tabChildStyle}>
                            <MetricsPanel asset={asset}/>
                        </div>
                    },
                    {
                        key: "ai",
                        label: "Holi's comment",
                        children: <div style={tabChildStyle}>
                            <AssetAiComment asset={asset}/>
                        </div>
                    },
                    {
                        key: "peers",
                        label: "Peers",
                        children: <div style={tabChildStyle}>
                            <PeersPanel asset={asset}/>
                        </div>
                    },
                ]}
                size={"small"}
            />
        </Spin>
    </Card>
}

export {CompanyCard, CompanyCardTooltip, MetricsPanel, MainPanel, RatingsPanel};
