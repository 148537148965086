import {Alert, Button, Col, Row, Space, Tag, Tooltip, Typography} from "antd";
import React from "react";
import {formatterMapper} from "@components/supervision/report_constructor/tree_utils/utils";
import {Link} from "react-router-dom";
import ValueFormatter from "@global/ValueFormatter";

// const activeBadgeProps = {status: "processing", color: "green"};
// const inactiveBadgeProps = {status: "default", color: "gray"};


const ClientMetricsResult = ({flag, updateLimit, resetLimit}) => {
    // const globalActive = flag.limit.type === "global" ? activeBadgeProps : inactiveBadgeProps;
    // const clientActive = flag.limit.type === "client" ? activeBadgeProps : inactiveBadgeProps;
    const metricFormatter = formatterMapper(flag.metric.units);
    const globalLimitValue = metricFormatter(flag.limit.global, flag.metric.decimals_precision);
    const clientLimitValue = metricFormatter(flag.limit.client, flag.metric.decimals_precision);

    let formattedValue = metricFormatter(flag.metric.value, flag.metric.decimals_precision)

    const ClientValueComp = ({type}) => {
        if (flag.limit.type === type) {
            return <Tag color={flag.red_flag_raised ? "red" : "green"} className="supervision-metric-tag">
                Check result: {formattedValue}
            </Tag>
        } else {
            return <Tag color={flag.red_flag_raised ? "red" : "success"} className="supervision-metric-tag"
                        style={{visibility: 'hidden'}}>
                Check result: {formattedValue}
            </Tag>
        }
    }

    const defaultLimitComp = <Tooltip title={`Limit (${flag.limit.type})`}>
        <Tag className="supervision-metric-tag">
            Limit value: {globalLimitValue}
            {/* <Badge {...globalActive}
                   text={`Limit value: ${globalLimitValue}`}/> */}
        </Tag>
    </Tooltip>

    let clientLimitComp = null

    if (flag.limit['client'] != null) {
        clientLimitComp = <Tooltip title={`Limit (${flag.limit.type})`}>
            <Tag className="supervision-metric-tag">
                Client limit: {clientLimitValue}
                {/* <Badge {...clientActive}
                       text={`Client limit: ${clientLimitValue}`}/> */}
            </Tag>
        </Tooltip>
    }

    let defaultRow = <Row key={'def_row'}>
        <Col>
            <ClientValueComp type={'global'}/>
        </Col>
        <Col>
            <span className="vs-span">vs</span>
            {defaultLimitComp}
        </Col>
    </Row>

    let clientRow = <Row key={'client_row'}>
        <Col>
            <ClientValueComp type={'client'}/>
        </Col>
        <Col>
            <span className="vs-span">vs</span>
            {clientLimitComp}
        </Col>
    </Row>

    let comps = []
    if (flag.limit.type == 'client') {
        comps.push(clientRow)
        let comment = <Typography.Text italic>{flag.limit.comment}</Typography.Text>
        let tmstp = new Date(flag.limit.timestamp + "Z").toLocaleDateString("en-US")
        let description = <Typography.Text>The reason was stated as: {comment} by {flag.limit.user} on {tmstp}.</Typography.Text>
        const alert = <Alert
            key={'limit_alert'}
            message={`The limit for this client overrides the default limit, ${globalLimitValue}.`}
            style={{marginTop: 12}}
            type="info" action={
            <Space direction={'vertical'}>
                <Button className={'small-button'} size="small" type="link" onClick={() => updateLimit()}>
                    Update
                </Button>
                <Button className={'small-button'} size="small" type="link" onClick={() => resetLimit()}>
                    Delete
                </Button>
            </Space>
        } description={description}/>
        comps.push(alert)

    } else {
        comps.push(defaultRow)
        let oldValue = flag.limit.global
        let message = `The default limit value is used for this check. Push <Button>Hello</Button> to set custom limit for a client.`
        
        if (clientLimitComp != null) {
            //comps.push(clientRow)
            message = `The default limit value is used for this check, despite the client has it's own limit: ${clientLimitValue}`
            oldValue = flag.limit.client
        }
        
        let text = <Typography.Text className={'text'} type={"secondary"} key={'limit_text'}>
            Default limit value is used.{" "}
            <a onClick={() => updateLimit()}>
                Set custom limit
            </a>
            {" "}for this client if it should be processed differently.
        </Typography.Text>

        const alert = <Alert
            message={message}
            className={'silent-notice'}
            type="info" action={
            <Space direction={'vertical'}>
                <Button className={'small-button'} size="small" type="link" danger={false} onClick={() => updateLimit()}>
                    Update
                </Button>
            </Space>
        }/>
        comps.push(text)
    }

    return <div style={{width: '100%'}}>{comps}</div>
}

export default ClientMetricsResult
