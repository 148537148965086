import React from "react";
import {Flex, theme, Typography} from "antd";


const {useToken} = theme;


const CardHeader = ({
                        title,
                        icon,
                        controls = null,
                        tour = null,
                        style = {},
                        level = 4,
                        id
                    }) => {
    const {token} = useToken();

    return <Flex
        gap={"small"}
        justify={"space-between"}
        align={"baseline"}
        wrap={"wrap"}
        style={{marginBottom: token.padding, ...style}}
        id={id}
    >
        <Flex
            gap={"small"}
            align={"center"}
            id={`${id}-title`}
        >
            {icon &&
                <span style={{
                    fontSize: token.fontSizeHeading4,
                    fontWeight: token.fontWeightStrong,
                }}>
                    {icon}
                </span>
            }
            <Typography.Title level={level} style={{margin: 0}}>
                {title}
            </Typography.Title>
        </Flex>
        {controls}
        {tour
            && <div id={`${id}-tour`}>
                {tour}
        </div>}
    </Flex>

}

export default CardHeader;
