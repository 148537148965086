import React, {createContext, useContext, useEffect, useState} from "react";
import {message, Spin} from "antd";
import {useSearchParams} from "react-router-dom";
import {getAssets, getCollectionOptions, getSettings} from "@API/screener";


const entityGroupLabelMap = {
    company: {
        pl: "companies",
        sg: "company",
    },
    fund: {
        pl: "funds",
        sg: "fund",
    },
    portfolio: {
        pl: "portfolios",
        sg: "portfolio",
    }
}


const ScreenerContext = createContext(null);


const ScreenerContextProvider = ({
                                     entityGroup,
                                     children,
                                     autoLoad=true,
                                     inScreener=true,
                                     inProposalContext=false
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [settings, setSettings] = useState();
    const [limit, setLimit] = useState(12);
    const [assets, setAssets] = useState();
    const [totalAssetsCount, setTotalAssetsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingCollections, setLoadingCollections] = useState(false);
    const [loadingAssets, setLoadingAssets] = useState(true);
    const [loadingMoreAssets, setLoadingMoreAssets] = useState(false);
    const [fundActiveTab, setFundActiveTab] = useState('asset-alloc');
    const [portfolioActiveTab, setPortfolioActiveTab] = useState('asset-alloc');
    const entityGroupLabelSg = entityGroupLabelMap[entityGroup]?.sg;
    const entityGroupLabelPl = entityGroupLabelMap[entityGroup]?.pl;

    const fetchSettings = (abort_signal=null) => {
        setLoading(true)
        getSettings(entityGroup, (data, error) => {
            if (!error) {
                setSettings(data);
            } else {
                message.error("Something went wrong while loading screener settings!");
            }
            setLoading(false)
        }, abort_signal)
    }

    const updateCollectionOptions = (callback) => {
        getCollectionOptions(entityGroup, (data, error) => {
            if (!error) {
                setSettings(prev => {
                    if(prev) {
                        prev.filters.collection.options = data
                    }
                    return prev
                });
            } else {
                message.error("Something went wrong while loading collection options!");
            }
            callback(data, error)
        })
    }

    const fetchAssets = (abort_signal=null) => {
        setLoadingAssets(true);
        getAssets(entityGroup, searchParams.toString(),(data, error) => {
            if (!error) {
                setAssets(data);
                setTotalAssetsCount(data && data.length > 0 ? data[0]._count : 0)
            } else {
                setAssets([]);
                setTotalAssetsCount(0)
                message.error(`Something wrong happened while searching ${entityGroupLabelPl}!`);
            }
            setLoadingAssets(false);
        }, abort_signal, 0, limit)
    }

    const fetchMoreAssets = (abort_signal=null) => {
        setLoadingMoreAssets(true);
        const usedSearchParamsString = searchParams.toString()
        getAssets(entityGroup, searchParams.toString(),(data, error) => {
            if (!error) {
                if (usedSearchParamsString === searchParams.toString()) {
                    setAssets([...assets, ...data]);
                }
            } else {
                message.error(`Something went wrong while searching ${entityGroupLabelPl}!`);
            }
            setLoadingMoreAssets(false);
        }, abort_signal, assets?.length, limit)
    }

    useEffect(() => {
        const controller = new AbortController()
        fetchSettings(controller.signal)
        return () => {
            controller.abort()
        }
    }, [])

    useEffect(() => {
        if(autoLoad) {
            const controller = new AbortController()
            fetchAssets(controller.signal)
            return () => {
                controller.abort()
            }
        }
    }, [searchParams])


    return <ScreenerContext.Provider
        value={{
            entityGroup,
            limit,
            settings,
            updateCollectionOptions,
            searchParams,
            setSearchParams,
            assets,
            totalAssetsCount,
            fundActiveTab,
            setFundActiveTab,
            portfolioActiveTab,
            setPortfolioActiveTab,
            loadingCollections,
            setLoadingCollections,
            loadingAssets,
            loadingMoreAssets,
            entityGroupLabelSg,
            entityGroupLabelPl,
            fetchSettings,
            fetchAssets,
            fetchMoreAssets,
            inScreener,
            inProposalContext
        }}
    >
        <Spin
            spinning={loading}
            tip="Loading screener..."
        >
            {children}
        </Spin>
    </ScreenerContext.Provider>

}


const useScreenerContext = () => {
    const context = useContext(ScreenerContext)
    if (!context) {
        throw new Error(
            "useScreenerContext must be used within ScreenerContextProvider"
        )
    }
    return context;
}


export {ScreenerContextProvider, useScreenerContext}
