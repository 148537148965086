import React, {useState} from "react";
import {
    Button,
    Card, Flex,
    theme,
    Typography
} from 'antd';
import {ArrowRightOutlined} from "@ant-design/icons";
import NestedCard from "@components/retail/cards/NestedCard";
import TourButton from "@components/retail/cards/TourButton";
import CardHeader from "@components/retail/cards/CardHeader";


const {useToken} = theme;


export const ScreenerLink = ({title, href, style={}, id}) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <a
        href={href}
        target={"_blank"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={style}
    >
        <Flex gap={"small"} id={id} style={{
            color: hover ? "white" : token.colorText,
            // background: "rgba(0, 0, 0, 0.1)",
            background: hover ? token.colorPrimary : "white",
            borderRadius: 60,
            lineHeight: 1,
            padding: `${token.paddingXS}px ${token.paddingSM}px`,
            fontSize: token.fontSizeSM,
            cursor: "pointer"
        }}>
            {title}
            <ArrowRightOutlined/>
        </Flex>
    </a>
}


const ScreenerCardTour = ({}) => {
    const steps = [
        {
            title: "Company collections in screener",
            target: "screener-card-companies",
        },
        {
            title: "Click to go to companies with high equity rating",
            target: "screener-card-companies-high-rating",
        },
        {
            title: "Fund collections in screener",
            target: "screener-card-funds",
        },
        {
            title: "Model portfolio collections in screener",
            target: "screener-card-models",
        },
    ];

    return <TourButton steps={steps}/>
}


const ScreenerCard = ({}) => {
    const {token} = useToken();

    return <Card hoverable style={{
        height: "100%",
        minHeight: 300,
        cursor: "default",
        background: token.colorBgGrey,
        borderColor: "rgb(220, 220, 220)",
        // flexGrow: 1
    }}>
        <NestedCard background={"white"} style={{paddingBottom: 0}}>
            <CardHeader
                title={"Screener"}
                tour={<ScreenerCardTour/>}
                id={"screener-card-header"}
            />
        </NestedCard>
        <div id={"screener-card-companies"}>
            <Typography.Title level={5}
                              style={{marginTop: 0, fontSize: token.fontSize}}>
                Companies
            </Typography.Title>
            <Flex gap={"small"} wrap={"wrap"}>
                <ScreenerLink
                    title={"High rating"}
                    href={"screener/company?sscore_plus.from=70"}
                    id={"screener-card-companies-high-rating"}
                />
                <ScreenerLink
                    title={"High dividends"}
                    href={"screener/company?div_yield.from=3"}
                />
                <ScreenerLink
                    title={"All companies"}
                    href={"screener/company"}
                />
            </Flex>
        </div>
        <div id={"screener-card-funds"}>
            <Typography.Title level={5} style={{fontSize: token.fontSize}}>
                Funds
            </Typography.Title>
            <Flex gap={"small"} wrap={"wrap"}>
                <ScreenerLink
                    title={"Stock ETFs"}
                    href={"screener/fund?entity=etf&asset_class=stocks"}
                />
                <ScreenerLink
                    title={"Bond ETFs"}
                    href={"screener/fund?entity=etf&asset_class=bonds"}
                />
                <ScreenerLink
                    title={"ETFs"}
                    href={"screener/fund?entity=etf"}
                />
                <ScreenerLink
                    title={"Mutual funds"}
                    href={"screener/fund?entity=fund"}
                />
                <ScreenerLink
                    title={"All funds"}
                    href={"screener/fund"}
                />
            </Flex>
        </div>
        <div id={"screener-card-models"}>
            <Typography.Title level={5} style={{fontSize: token.fontSize}}>
                Models
            </Typography.Title>
            <Flex gap={"small"} wrap={"wrap"}>
                <ScreenerLink
                    title={"High risk"}
                    href={"screener/portfolio?risk_score.from=61"}
                />
                <ScreenerLink
                    title={"Above-average risk"}
                    href={"screener/portfolio?risk_score.from=56&risk_score.to=60"}
                />
                <ScreenerLink
                    title={"Moderate risk"}
                    href={"screener/portfolio?risk_score.from=42&risk_score.to=55"}
                />
                <ScreenerLink
                    title={"Below-average risk"}
                    href={"screener/portfolio?risk_score.from=28&risk_score.to=41"}
                />
                <ScreenerLink
                    title={"Low risk"}
                    href={"screener/portfolio?risk_score.to=27"}
                />
            </Flex>
        </div>
    </Card>
}

export default ScreenerCard;
