import React, {useMemo, useState} from "react";
import {Card, Flex, theme, Tooltip, Typography} from "antd";
import {
    BulbOutlined,
    ExportOutlined,
    MailOutlined, QuestionCircleFilled,
    QuestionCircleOutlined, QuestionOutlined,
    RedditOutlined,
    RightOutlined,
    YoutubeOutlined
} from "@ant-design/icons";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useLocation} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


const HelpItemCard = ({
                          title,
                          description,
                          icon,
                          href,
                          outer = true,
                          onClick
                      }) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <a href={href} target={outer ? "_blank" : "_self"}>
        <Card
            type={"inner"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                background: hover ? token.colorBgHoverBlue : token.colorBgBase,
                cursor: "pointer"
            }}
            onClick={onClick}
        >
            <Flex gap={"middle"} align={"center"}>
                <Flex align={"center"} justify={"center"} style={{
                    background: "rgba(0,0,0,0.03)",
                    height: 60,
                    width: 60,
                    borderRadius: 60,
                    fontSize: token.fontSizeXL,
                    flexShrink: 0
                }}>
                    {icon}
                </Flex>
                <Flex vertical align={"flex-start"} style={{flexGrow: 1}}>
                    <Typography.Text
                        style={{fontWeight: token.fontWeightStrong}}>
                        {title}
                    </Typography.Text>
                    <Typography.Text style={{
                        color: token.colorTextDescription,
                        fontSize: token.fontSizeSM,
                        lineHeight: 1
                    }}>
                        {description}
                    </Typography.Text>
                </Flex>
                {outer ? <ExportOutlined/> : <RightOutlined/>}
            </Flex>
        </Card>
    </a>
}


export const HelpCenterMenuItem = ({}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Flex
        align={"center"}
        onClick={(e) => {
            e.stopPropagation();
        }}
        style={{height: "100%"}}
    >
        <Tooltip title={"Help center"}>
            <Flex gap={"small"} align={"baseline"}>
                <Flex
                    gap={"large"}
                    align={"center"}
                    justify={"center"}
                    onMouseEnter={() => {
                        setHover(true)
                    }}
                    onMouseLeave={() => {
                        setHover(false)
                    }}
                    style={{
                        background: hover ? token.colorBgGrey : token.colorBgGreyDark,
                        height: 32,
                        width: 32,
                        borderRadius: 32,
                        border: "none",
                        cursor: "pointer"
                    }}
                    onClick={(e) => setOpenDrawer(true)}
                >
                    <QuestionOutlined/>
                </Flex>
                <span className={"hide-in-horizontal-menu"}>
                    Help center
                </span>
            </Flex>
        </Tooltip>
        <MobileDrawer
            open={openDrawer}
            onClose={() => {
                setOpenDrawer(false);
            }}
            width={600}
            id={"help-center-drawer"}
        >
            <HelpCenter onClose={() => setOpenDrawer(false)}/>
        </MobileDrawer>
    </Flex>
}


const HelpCenter = ({onClose}) => {
    const {setOpenTour} = useAuth();
    const location = useLocation();
    const {token} = useToken();

    const tourToOpenOnClick = useMemo(() => {
        if (location.pathname.startsWith("/individual/dashboard")) {
            return "dashboard-page";
        }
        if (location.pathname.startsWith("/individual/portfolio")) {
            return "portfolio-page";
        }
        if (location.pathname.startsWith("/individual/screener")) {
            return "screener-page";
        }
        if (location.pathname.startsWith("/individual/marketplace")) {
            return  "marketplace-page";
        }
        return null;
    }, [location.pathname])

    return <Flex vertical gap={"small"}>
        <HelpItemCard
            title={"Reddit"}
            description={"Ask your question on Reddit"}
            icon={<RedditOutlined/>}
            href={"https://www.reddit.com/r/holisticoinvest/"}
            outer={true}
        />
        <HelpItemCard
            title={"Email"}
            description={<>
                Email us at {" "}
                <Typography.Text copyable style={{
                    fontSize: token.fontSizeSM,
                    color: token.colorTextDescription
                }}>
                    administrator@holisticoinvest.com
                </Typography.Text>
            </>}
            icon={<MailOutlined/>}
            href={"mailto: administrator@holisticoinvest.com"}
            outer={true}
        />
        <HelpItemCard
            title={"Tutorial"}
            description={"Watch video tutorial on YouTube"}
            icon={<YoutubeOutlined/>}
            href={"https://youtu.be/AhHsuGXscpo"}
            outer={true}
        />
        {tourToOpenOnClick
            && <HelpItemCard
                title={"Tour"}
                description={"Take educational tour for this page"}
                icon={<BulbOutlined/>}
                outer={false}
                onClick={() => {
                    setOpenTour(tourToOpenOnClick);
                    setTimeout(onClose, 250);
                }}
            />}
    </Flex>
}


export default HelpCenter;
