import {useCallback} from 'react';


const TWITTER_EVENT_CODES = {
    'Lead': 'no_twitter_analog',
    'SignUp': 'tw-ovf81-ovf83',
    'ViewContent': 'no_twitter_analog',
    'AddToWishlist': 'no_twitter_analog',
    'AddToCart': 'tw-ovf81-ovf85',
    'Purchase': 'tw-ovf81-ovf82',
}

export const initTwitter = () => {
    if (process.env.REACT_APP_TWITTER_ID != null) {
        if (typeof window.twq === 'function') {
            window.twq('config', process.env.REACT_APP_TWITTER_ID);
            console.info('Twitter tracker initialized')
        }
    } else {
        console.warn('Twitter tracker disabled')
    }
}

export const initReddit = () => {
    if (process.env.REACT_APP_REDDIT_ID != null) {
        if (typeof window.rdt === 'function') {
            window.rdt('init', process.env.REACT_APP_REDDIT_ID);
            console.debug('+Reddit tracker initialized')
        }
    } else {
        console.warn('Reddit tracker disabled')
    }
}

export const useAdsTrack = () => {
    const track = useCallback((eventName, params = {}) => {
        notifyEveryone(eventName, params);
    }, []);

    return track;
}

export const notifyEveryone = (eventName, params = {}) => {
    sendTwitterEvent(eventName, params);
    sendRedditEvent(eventName, params);
}

export const sendTwitterEvent = (eventName, params = {}) => {
    if (process.env.REACT_APP_TWITTER_ID != null) {
        const twitterEventCode = TWITTER_EVENT_CODES[eventName]

        if (typeof window.twq === 'function') {
            console.debug('conversion event twitter ' + twitterEventCode, params);
            window.twq('event', twitterEventCode, params);
        }
    }
}

export const sendRedditEvent = (eventName, params = {}) => {
    if (process.env.REACT_APP_REDDIT_ID != null) {
        if (typeof window.rdt === 'function') {
            console.debug('conversion event reddit ' + eventName, params);
            window.rdt('track', eventName, params);
        }
    }
}
