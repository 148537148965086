import axiosInstance from "@API/APIClient";


const get_all_users_emails = async (settings) => {
    let url = `/users/all-emails`
    return await axiosInstance.get(url, settings)
}

const acceptTnc = async () => {
    const url = '/users/accept_tnc'
    return await axiosInstance.post(url)
};

const updateTourStatus = async (status) => {
    const url = '/users/tour-status'
    return await axiosInstance.post(url, status);
};

export {
    get_all_users_emails,
    acceptTnc,
    updateTourStatus
}