/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Button, Flex, Form, InputNumber, Radio} from "antd";
import {NumericFilterTipButton} from "@components/screener/params/NumericFilter";
import {useScreenerContext} from "@hooks/ScreenerContext";
import {updateSearchParams} from "@components/screener/params/utils";


const AllocationFilterItem = ({label, name, tips, disabled=false}) => {
    return <div style={{
        padding: "4px 4px 4px 24px",
        border: "1px #e3e3e3 solid",
        borderRadius: 8,
        marginBottom: 8,
        fontSize: 16,
        fontWeight: 500,
    }}>
        {label}
        <Flex wrap={"wrap"}>
            <Flex align={"center"} style={{marginRight: 24}}>
                <Form.Item
                    name={`${name}.from`}
                    style={{display: 'inline-block'}}
                >
                    <InputNumber
                        controls={false}
                        placeholder={"e.g. 0"}
                        size={"small"}
                        style={{width: 80}}
                        disabled={disabled}
                    />
                </Form.Item>
                <span style={{margin: "0 8px"}}>-</span>
                <Form.Item
                    name={`${name}.to`}
                    style={{display: 'inline-block'}}
                >
                    <InputNumber
                        controls={false}
                        placeholder={"e.g. 100"}
                        size={"small"}
                        style={{width: 80}}
                        disabled={disabled}
                    />
                </Form.Item>
            </Flex>
            {tips
                &&
                <Flex align={"center"} wrap={"wrap"} style={{margin: "0 0 0 -8px"}}>
                    {tips.map(tip => {
                        return <NumericFilterTipButton
                            key={`${name}: ${tip.label}`}
                            name={`${name}`}
                            from={tip.from}
                            to={tip.to}
                            disabled={disabled}
                        >
                            {tip.label}
                        </NumericFilterTipButton>
                    })}
                </Flex>
            }
        </Flex>
    </div>
}


const AllocationFilterItemHidden = ({name}) => {
    return <>
        <Form.Item name={`${name}.from`} hidden={true}/>
        <Form.Item name={`${name}.to`} hidden={true}/>
    </>
}


const AllocationFilter = ({item, disabled=false, id}) => {
    const {settings, setSearchParams} = useScreenerContext()
    const form = Form.useFormInstance()
    const [collapsed, setCollapsed] = useState(true)

    const nItems = settings.filters[item.name].items.length
    const showMax = 3
    const collapsible = nItems > showMax

    const tipOptions = []
    if (item.tips && item.tips.length > 0) {
        item.tips.map(tip => tipOptions.push({label: tip.label, value: tip.name}))
        tipOptions.push({label: "Custom", value: "custom"})
    }

    const onClearAll = (e) => {
        const params = {}
        params[item.name] = null
        settings.filters[item.name].items.map((_, index) => {
            params[`${item.name}.${index}.from`] = null;
            params[`${item.name}.${index}.to`] = null;
        })
        setSearchParams(prev => updateSearchParams(prev, params, settings, form))
    }

    return <div id={id}>
         {tipOptions.length > 0
            &&
            <Form.Item name={item.name}>
                <Radio.Group disabled={disabled} style={{display: "flex", flexDirection: "column"}}>
                    {tipOptions.map(option => {
                        return <Radio
                            value={option.value}
                            key={option.value}
                            style={{height: 28}}
                        >
                            {option.label}
                        </Radio>
                    })}
                </Radio.Group>
            </Form.Item>
        }
        <Flex justify={"flex-end"}>
            <Button
                onClick={onClearAll}
                type={"link"}
                size={"small"}
                className={'auxiliary-button'}
                disabled={disabled}
            >
                Clear all
            </Button>
        </Flex>
        {item.items.map((allocationItem, index) => {
            // Need all items for the form to work correctly, even if collapsed
            return <>{
                collapsible && collapsed && index >= showMax
                    ?
                    <AllocationFilterItemHidden name={`${item.name}.${index}`}/>
                    :
                    <AllocationFilterItem
                        label={allocationItem.label}
                        name={`${item.name}.${index}`}
                        tips={item.item_tips}
                        key={`${item.name}.${index}`}
                        hidden={collapsible && collapsed && index >= showMax}
                        disabled={disabled}
                    />
            }</>})
        }
        {collapsible && collapsed
            &&
            <Button
                size={"small"}
                type={"link"}
                onClick={() => {
                    setCollapsed(false)
                }}
                style={{marginLeft: 16}}
            >
                Show more...
            </Button>
        }
        {collapsible && !collapsed
            &&
            <Button
                size={"small"}
                type={"link"}
                onClick={() => {
                    setCollapsed(true)
                }}
                style={{marginLeft: 16}}
            >
                Show less...
            </Button>
        }
    </div>
}


export default AllocationFilter;
