import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import packageInfo from "../../package.json";
import React, {useEffect} from "react";
import Text from "antd/es/typography/Text";
import CacheBuster from "react-cache-buster";
import AppSpas from "@app/AppSpas";
import AppRia from "@app/AppRia";
import AppRetail from "@app/AppRetail";
import {useAuth} from "@hooks/AuthHook";
import HolisticoFork from "@app/layout/HolisticoFork";
import Constants from "@global/Constants";
import {Spin} from "antd";
import ReactGA from 'react-ga4';
import "./App.less"
import {PageNotFound} from "@app/layout/retail/RetailLayout";


const TRACKING_ID = process.env.REACT_APP_GANAL_ID || null;
if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
}

const AppRouter = ({}) => {
    const auth = useAuth();
    const version = packageInfo.version;
    const location = useLocation()
    const MODE = process.env.REACT_APP_UI_VERSION

    const V2 = MODE && MODE.includes('holistico');

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: location.pathname.replaceAll('/', ' ').slice(1),
            // category: location.pathname.split('/').at(-1),
            // action: "Page view",
            // label: "Page view",
        });
    }, [location])

    if (auth.authLoading === true) {
        console.info('awaiting auth...')
        return <Spin size="large"
                     fullscreen={true} spinning={true}/>

    } else {
        let index_url = Constants.SPAS_URL

        if (V2) {
            //index_url = Constants.RECOGNITION_URL
            index_url = Constants.RETAIL_URL
            if (auth.advisorAllowed()) {
                index_url = Constants.RIA_URL
            } else if (auth.retailAllowed()) {
                index_url = Constants.RETAIL_URL
            }
        }

        return <CacheBuster
            currentVersion={version}
            isEnabled={true}
            isVerboseMode={true}
            loadingComponent={<Text>version
                check...</Text>}
            onCacheClear={() => {
                console.log("cleared the cache")
            }}>
            <Routes>
                <Route path="/" element={<Navigate to={index_url} replace={true}/>}/>
                {!V2 && <Route path="/spas/*" element={<AppSpas/>}/>}
                {<Route path={`/${Constants.RECOGNITION_URL}`} element={<HolisticoFork/>}/>}
                <Route path={`/${Constants.RIA_URL}/*`} element={<AppRia/>}/>
                <Route path={`/${Constants.RETAIL_URL}/*`} element={<AppRetail/>}/>
                <Route path="/view/:externalId/*" element={<AppRetail/>}/>
                <Route path="*" render={() => (<PageNotFound/>)}/>
            </Routes>
        </CacheBuster>
    }
}

export default AppRouter
