/* eslint-disable react/prop-types */
import React from 'react';
import '@app/AppSpas.less';
import {App, ConfigProvider, Flex, theme, Typography} from "antd";
import {AssetScreener} from "@components/screener/AssetScreener";
import TourButton, {PageTourModal} from "@components/retail/cards/TourButton";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


export const ScreenerSpasStyle = () => {
    const {token} = useToken();

    return <ConfigProvider
        theme={{
            token: {
                colorPrimary: '#3F8DFA',
                colorWarning: '#e0ad60',
                fontSizeHeading1: 48,
                fontFamily: 'Red Hat Display W',
                fontSize: 16,
                fontWeightStrong: 450,
                colorBgBase: '#ffffff',
                colorBgLayout: 'transparent',
                headerBg: 'white',
            }
        }}>
        <App className={'holistico'}>
            <Typography.Title level={3} style={{
                marginLeft: token.marginLG,
            }}>
                Screener
            </Typography.Title>
            <AssetScreener/>
        </App>
    </ConfigProvider>
}


export const AssetScreenerTour = ({buttonStyle}) => {
    const {openTour, setOpenTour} = useAuth();
    const {token} = useToken();

    const steps = [
        {
            title: "Choose asset type to search",
            target: "screener-menu",
        },
        // {
        //     title: "Click to search companies",
        //     target: "screener-menu-item-company",
        // },
        // {
        //     title: "Click to search funds",
        //     target: "screener-menu-item-fund",
        // },
        // {
        //     title: "Click to search model portfolios",
        //     target: "screener-menu-item-portfolio",
        // },
        {
            title: "Choose search parameters",
            target: "screener-filter-item-1"
        },
        {
            title: "Start typing ticker or name to search",
            description: "Press Enter to pin the result and start another search",
            target: "screener-search"
        },
        {
            title: "Choose sort order",
            target: "screener-sort",
        },
        {
            title: "Choose info tab to display by default on asset cards",
            target: "screener-tab-select"
        },
        {
            title: "Click to add to / remove from favorites",
            target: "screener-asset-0-header-buttons-favorites",
        },
        {
            title: "Click to add to / remove from custom collection",
            target: "screener-asset-0-header-buttons-custom-collections",
        },
        {
            title: "Click to add to your portfolio",
            target: "screener-asset-0-header-buttons-get",
        },
        {
            title: "Choose collections to show",
            target: "screener-filter-item-0"
        },
        {
            title: "Click to create your own collection",
            target: "screener-create-custom-collection"
        },
        {
            title: "Click to see more assets",
            target: "screener-show-more",
        }
    ];

    return <TourButton
        steps={steps}
        bgColor={token.colorBgGrey}
        hoverBgColor={token.colorBgBase}
        bordered
        buttonStyle={buttonStyle}
        openTour={openTour === "screener-page" || openTour === "marketplace-page"}
        setOpenTour={() => setOpenTour(null)}
    />
}


const Screener = ({inProposalContext=false}) => {
    const {token} = useToken();

    return (
        <>
            <Flex gap={"large"} align={"center"} style={{
                marginLeft: token.marginLG,
                marginTop: token.marginXL,
                marginBottom: token.marginLG,
            }}>
                <Typography.Title level={3} style={{
                    margin: 0,
                }}>
                    Screener
                </Typography.Title>
                <AssetScreenerTour/>
                <PageTourModal tourKey={"screener-page"}/>
            </Flex>
            <AssetScreener inProposalContext={inProposalContext}/>
        </>
    );
}

export default Screener;
