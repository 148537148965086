/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {
    Card,
    Col,
    Divider,
    Row,
    Spin,
    Tabs,
    Descriptions, Flex, Tooltip, Typography, theme, Badge
} from 'antd';
import {green, red, grey} from '@ant-design/colors';
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useScreenerContext} from "@hooks/ScreenerContext";
import CardHeader, {
    CardHeaderCollectionButtons
} from "@components/screener/card/CardHeader";
import StatsCard from "@components/screener/card/StatsCard";
import DescriptiveInfo from "@components/screener/card/DescriptiveInfo";
import AssetClassPie from "@components/screener/chart/AssetClassPie";
import SizeStyleHeat from "@components/screener/chart/SizeStyleHeat";
import FISectorBar from "@components/screener/chart/FISectorBar";
import CreditRatingBar from "@components/screener/chart/CreditRatingBar";
import MaturityDate from "@components/screener/chart/MaturityDate";
import RatingRadar from "@components/screener/chart/RatingRadar";
import SectorBar from "@components/screener/chart/SectorBar";
import {
    getToleranceLevelByRiskScore
} from "@holistico/proposal/view/client/utils";
import {
    getAssetAllocationLabel
} from "@holistico/proposal/new/preview/StatsList";
import Localizator from "@global/Localizator";
import {CheckCircleFilled, ExclamationCircleFilled} from "@ant-design/icons";
import {LockPlaceholder} from "@components/retail/cards/LockTooltip";
import {
    AssetAiComment,
    PeersPanel
} from "@components/screener/card/CompanyCard";


const {useToken} = theme;


const PortfolioCardHeader = ({
                                 asset,
                                 loading,
                                 setLoading,
                                 twoLine = false,
                                 id
                             }) => {
    const {token} = useToken();

    const provider = asset.provider;
    let name = asset.name.replace(provider + " ", "");
    let background = 'rgb(248, 248, 248)'
    switch (provider) {
        case "BlackRock":
            background = 'rgb(0, 0, 0)';
            break;
        case "JPMorgan":
            background = '#936846';
            break;
        case "Signet":
            background = '#3196B2';
            break;
        case "GoldmanSachs":
            background = '#7297c5';
            break;
    }

    const textStyle = {
        fontSize: twoLine ? 20 : 24,
        fontWeight: 500,
        lineHeight: 1.2,
    }

    return <Flex
        justify={"space-between"}
        align={"flex-start"}
        style={{
            margin: `-${token.paddingLG}px -${token.paddingLG}px ${token.padding}px -${token.paddingLG}px`,
            borderTopLeftRadius: token.borderRadiusLG,
            borderTopRightRadius: token.borderRadiusLG,
            borderBottom: `1px solid ${token.colorFill}`,
            overflow: "hidden"
        }}
    >
        <Flex
            justify={"space-between"}
            align={"flex-start"}
            style={{
                padding: `${token.paddingLG}px ${token.paddingLG}px ${token.paddingSM}px ${token.paddingLG}px`,
                borderTopRightRadius: token.borderRadiusLG,
                flexGrow: 1,
                background: 'white',
                // borderLeft: `1px solid ${background}`
            }}
        >
            <Tooltip title={asset.name}>
                <Flex vertical gap={"small"} style={{
                    width: "calc(100% - 120px)",
                    height: twoLine ? 80 : "auto"
                }}>
                    <Typography.Paragraph
                        ellipsis={{rows: twoLine ? 2 : 1}}
                        style={{...textStyle, marginBottom: 0}}
                    >
                        {name}
                        <span style={{
                            ...textStyle,
                            color: "#e0ad60",
                            marginLeft: 12
                        }}>
                            {asset.ticker}
                        </span>
                    </Typography.Paragraph>
                    <Flex gap={"small"} style={{color: background,}}>
                        {/*<Badge color={background}/>*/}
                        <CheckCircleFilled style={{color: background}}/>
                        {Localizator.t('screener.portfolio.provider.' + provider)}
                    </Flex>
                </Flex>
            </Tooltip>
            <CardHeaderCollectionButtons
                asset={asset}
                loading={loading}
                setLoading={setLoading}
                twoLineHeader={twoLine}
                id={`${id}-buttons`}
            />
        </Flex>
    </Flex>
}

const MainPanel = ({
                       asset,
                       lockRatings = false,
                       lockDividendYield = false,
                       lockExpenseRatio = false
                   }) => {
    const risk_score = asset.calculated_values?.risk?.risk_score;
    const sscore_plus = asset.calculated_values?.score_markers !== -1
        ? asset.calculated_values?.score_markers?.find(marker => marker.label === 'sscore_plus')?.value
        : null;
    const dividends = asset.calculated_values.annual_dividend;
    const expense_ratio = asset.calculated_values.expense_ratio;

    return <Row gutter={[16, 16]} style={{marginBottom: 16, minHeight: 90}}>
        <Col span={6}>
            <StatsCard
                title={"Risk Score"}
                value={!is_null(risk_score) ? ValueFormatter.int_number(risk_score) : "-"}
                progress={!is_null(risk_score) ? risk_score : null}
                progressColor={risk_score > 60 ? red[5] : (risk_score < 28 ? green[5] : 'rgba(0, 0, 0, 0.8)')}
                style={{minHeight: "90px"}}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={Localizator.t("factor.sscore_plus")}
                value={!is_null(sscore_plus) ? ValueFormatter.int_number(sscore_plus) : "-"}
                progress={!is_null(sscore_plus) ? sscore_plus : null}
                progressColor={sscore_plus > 55 ? green[5] : (sscore_plus < 40 ? red[5] : 'rgba(0, 0, 0, 0.8)')}
                style={{minHeight: "90px"}}
                locked={lockRatings}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={"Distribution Rate"}
                value={dividends ? ValueFormatter.int_percent(dividends) : '-'}
                style={{minHeight: "90px"}}
                locked={lockDividendYield}
            />
        </Col>
        <Col span={6}>
            <StatsCard
                title={"Expense Ratio"}
                value={!is_null(expense_ratio) ? ValueFormatter.int_percent(expense_ratio) : '-'}
                valueColor={expense_ratio >= 0.8 ? red[5] : (expense_ratio <= 0.2 ? green[5] : 'rgba(0, 0, 0, 0.8)')}
                style={{minHeight: "90px"}}
                locked={lockExpenseRatio}
            />
        </Col>
    </Row>
}


const MetricsPanel = ({asset}) => {
    const risk = asset.calculated_values?.risk?.risk_score;
    const expense_ratio = asset.calculated_values.expense_ratio;
    const provider = asset.provider;

    return <Descriptions
        bordered
        items={[
            {
                key: "risk-level",
                label: "Risk",
                children: risk ? getToleranceLevelByRiskScore(risk) : "-",
            },
            {
                key: "provider",
                label: "Stocks / Bonds",
                children: getAssetAllocationLabel(asset.calculated_values),
            },
            {
                key: "provider",
                label: "ETFs or funds",
                children: expense_ratio > 0.01 ? "Yes" : "No",
            },
            {
                key: "provider",
                label: "Provider",
                children: Localizator.t('screener.portfolio.provider.' + provider),
            },
        ]}
        size={"small"}
        column={1}
        contentStyle={{fontSize: 14, padding: "4px 8px"}}
        labelStyle={{fontSize: 14, padding: "4px 8px"}}
    />
}


const InfoTagsPanel = ({asset}) => {
    const {token} = useToken();

    const risk = asset.calculated_values?.risk?.risk_score;
    const riskColor = risk > 60 ? red[6] : (risk < 28 ? green[6] : 'rgba(0, 0, 0, 0.6)')

    const alloc = asset.calculated_values?.asset_class;
    const stocks = alloc && alloc !== -1 ? (alloc["US Equity"] ?? 0) + (alloc["Non-US Equity"] ?? 0) : null;
    const bonds = alloc && alloc !== -1 ? (alloc["US Bond"] ?? 0) + (alloc["Non-US Bond"] ?? 0) : null;

    return <Flex gap={"middle"} wrap={"wrap"}>
        {risk &&
            <Flex gap={"small"}
                  style={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      lineHeight: 1.2,
                      borderRadius: token.borderRadiusLG
                  }}
            >
                <ExclamationCircleFilled style={{color: riskColor}}/>
                {getToleranceLevelByRiskScore(risk)} risk
            </Flex>
        }
        {!is_null(stocks) && !is_null(bonds)
            && <Flex gap={"small"}
                     style={{
                         paddingLeft: token.paddingXS,
                         paddingRight: token.paddingSM,
                         paddingTop: "4px",
                         paddingBottom: "4px",
                         lineHeight: 1.2,
                         borderRadius: token.borderRadiusLG
                     }}
            >
                <ExclamationCircleFilled style={{color: 'rgba(0, 0, 0, 0.6)'}}/>
                {`${ValueFormatter.round(100 * stocks, 0)}% stocks / ${ValueFormatter.round(100 * bonds, 0)}% bonds`}
            </Flex>
        }
    </Flex>

}


const TabsPanel = ({asset, lockRatings = false, lockAllocations = false}) => {
    const {portfolioActiveTab} = useScreenerContext();
    const [activeKey, setActiveKey] = useState(portfolioActiveTab);

    useEffect(() => {
        setActiveKey(portfolioActiveTab);
    }, [portfolioActiveTab])

    const chartContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: 250
    }
    const items = [
        {
            key: "ai",
            label: "Holi's comment",
            children: <div style={{minHeight: 250}}>
                 <AssetAiComment asset={asset}/>
            </div>
        },
        {
            key: 'asset-alloc',
            label: 'Asset allocation',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Asset allocation"} stuff={"data"}/>
                    : <AssetClassPie allocation={asset.calculated_values.asset_class}/>
                }
            </div>,
        },
        {
            key: 'ratings',
            label: 'Ratings',
            children: <div style={chartContainerStyle}>
                {lockRatings
                    ? <LockPlaceholder label={"Ratings"} stuff={"data"}/>
                    : <RatingRadar
                        asset={
                            asset.calculated_values.score_markers !== -1
                            && asset.calculated_values.score_markers?.reduce(
                                (obj, item) => Object.assign(obj, {[item.label]: item.value}),
                                {}
                            )
                        }
                    />
                }
            </div>,
        },
        {
            key: 'sector',
            label: 'Sector',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Sector"} stuff={"data"}/>
                    : <SectorBar allocation={asset.calculated_values.sector}/>
                }
            </div>,
        },
        {
            key: 'size-style',
            label: 'Size & style',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Size & style"} stuff={"data"}/>
                    : <SizeStyleHeat allocation={asset.calculated_values.size_style}/>
                }
            </div>,
        },
        {
            key: 'fi-sector',
            label: 'FI Sector',
            children: <div style={chartContainerStyle}>
                 {lockAllocations
                     ? <LockPlaceholder label={"FI Sector"} stuff={"data"}/>
                     : <FISectorBar allocation={asset.calculated_values.fi_super_sector}/>
                 }
            </div>,
        },
        {
            key: 'credit-rating',
            label: 'Credit rating',
            children: <div style={chartContainerStyle}>
                 {lockAllocations
                     ? <LockPlaceholder label={"Credit rating"} stuff={"data"}/>
                     : <CreditRatingBar allocation={asset.calculated_values.fi_credit_rating}/>
                 }
            </div>
        },
        {
            key: 'maturity-date',
            label: 'Maturity date',
            children: <div style={chartContainerStyle}>
                {lockAllocations
                    ? <LockPlaceholder label={"Maturity date"} stuff={"data"}/>
                    : <MaturityDate allocation={asset.calculated_values.fi_effective_maturity}/>
                }
            </div>,
        },
    ];
    return <div style={{
        background: "#fafafa",
        margin: "12px -24px -24px -24px",
        padding: "0 24px 24px 24px",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    }}>
        <Tabs
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            items={items}
            style={{marginTop: 24}}
            tabBarStyle={{}}
            size={"small"}
            type={"card"}
        />
    </div>
}


const PortfolioCard = ({
                           asset,
                           lockRatings = false,
                           lockDividendYield = false,
                           lockExpenseRatio = false,
                           lockAllocations = false,
                           id
                       }) => {
    const [loading, setLoading] = useState(false)

    return <Card
        hoverable
        style={{
            borderColor: "#e3e3e3",
            overflow: "hidden",
            cursor: "default"
        }}
        className={'screener-fund-card'}
    >
        <Spin spinning={loading}>
            <PortfolioCardHeader
                asset={asset}
                loading={loading}
                setLoading={setLoading}
                twoLine={true}
                tickerFirst={false}
                id={`${id}-header`}
            />
            <MainPanel
                asset={asset}
                lockRatings={lockRatings}
                lockDividendYield={lockDividendYield}
                lockExpenseRatio={lockExpenseRatio}
            />
            <InfoTagsPanel asset={asset}/>
            <TabsPanel asset={asset} lockRatings={lockRatings} lockAllocations={lockAllocations}/>
        </Spin>
    </Card>
}


export default PortfolioCard;
