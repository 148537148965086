import React, {useState} from "react";
import {Divider, List, Spin, theme, Tooltip, Typography} from "antd";
import {useRequest} from 'ahooks'
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {asyncGetExactAssetByTicker} from "@API/asset";
import {useAuth} from "@hooks/AuthHook";
import {
    ScreenerContextProvider,
    useScreenerContext
} from "@hooks/ScreenerContext";
import {CompanyCard} from "@components/screener/card/CompanyCard";
import FundCard from "@components/screener/card/FundCard";
import {useAiChatContext} from "@hooks/AiChatContext";


const {useToken} = theme;


const RETAIL_DISCLOSURE_NOTE = (
    "Keep in mind that I’m not " +
    "a financial advisor. Remember that all investment " +
    "decisions are your own, and it’s important to consult " +
    "a professional for personalized financial advice tailored " +
    "to your unique needs."
);


const parseMarkdown = (content) => {
    // Ticker pattern: [A-Z]{1,7}(?:[-\.][A-Z]{1,7})?
    // Possible cases: [AAPL], (AAPL), (Ticker: AAPL)
    const tickerRegex = /\[([A-Z]{1,7}(?:[-\.][A-Z]{1,7})?)\]|\((?:Ticker: )?([A-Z]{1,7}(?:[-\.][A-Z]{1,7})?)\)/g;
    const combinedRegex = /(?:\*\*(.*?)\*\*)|(?:#{1,6}\s+(.*?)(?:\n|$))/g;

    const tickers = [];
    content.replace(tickerRegex, (match, ticker) => {
        tickers.push(ticker);
    });

    let parts = [];
    let lastIndex = 0;

    content.replace(combinedRegex, (match, boldText, headerText, offset) => {
        if (offset > lastIndex) {
            const textBefore = content.slice(lastIndex, offset);
            parts.push({type: 'text', content: textBefore});
        }

        if (boldText) {
            parts.push({type: 'bold', content: boldText});
        } else if (headerText) {
            parts.push({
                type: 'header',
                content: headerText.replaceAll(':', '').replaceAll('**', '')
            });
        }

        lastIndex = offset + match.length;
    });

    if (lastIndex < content.length) {
        parts.push({type: 'text', content: content.slice(lastIndex)});
    }

    parts = parts.map(part => {
        if (part.type === 'text' || part.type === 'bold') {
            const tickerParts = [];
            part.content.split(tickerRegex).map((text, i) => {
                if(text) {
                    tickerParts.push({
                        type: i % 3 === 0 ? part.type : 'ticker',
                        content: text
                    })
                }
            });
            return tickerParts;
        }
        return [part];
    }).flat();

    parts = parts.filter(part => part.content.trim() !== '');

    return {parts, tickers};
};

const getCopyText = (content) => {
    return content.replaceAll("**", "").replaceAll("#", "");
}


const ScreenerSettingsWaiter = ({children}) => {
    const {loading, settings} = useScreenerContext();
    return <>{settings && !loading && children}</>
}


const TickerDrawer = ({visible, onClose, ticker}) => {
    const {isDataLocked} = useAuth();
    const {data: asset, loading} = useRequest(
        () => asyncGetExactAssetByTicker(ticker),
        {
            refreshDeps: [ticker],
            ready: !!ticker && visible
        }
    );

    return (
        <MobileDrawer
            title={null}
            placement="right"
            onClose={onClose}
            open={visible}
            width={600}
        >
            <Spin spinning={loading}>
                {asset?.data && asset?.data?.entity_group === "company"
                    && <ScreenerContextProvider entityGroup={"company"}
                                                autoLoad={false}
                                                inScreener={false}>
                        <ScreenerSettingsWaiter>
                            <CompanyCard
                                asset={asset.data}
                                lockRatings={isDataLocked("#security-ratings")}
                                lockDividendYield={isDataLocked("#security-dividend-yield")}
                            />
                        </ScreenerSettingsWaiter>
                    </ScreenerContextProvider>
                }
                {asset?.data && asset?.data?.entity_group === "fund"
                    && <ScreenerContextProvider entityGroup={"fund"}
                                                autoLoad={false}
                                                inScreener={false}>
                        <ScreenerSettingsWaiter>
                            <FundCard
                                asset={asset.data}
                                lockRatings={isDataLocked("#security-ratings")}
                                lockDividendYield={isDataLocked("#security-dividend-yield")}
                                lockExpenseRatio={isDataLocked("#security-expense-ratio")}
                                lockAllocations={isDataLocked('#security-allocations')}
                            />
                        </ScreenerSettingsWaiter>
                    </ScreenerContextProvider>
                }
            </Spin>
        </MobileDrawer>
    );
};


const ContentMarkdown = ({content}) => {
    const {retail} = useAiChatContext();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState(null);
    const {token} = useToken();

    const {parts, tickers} = parseMarkdown(content);

    const handleTickerClick = (ticker) => {
        setSelectedTicker(ticker);
        setDrawerVisible(true);
    };

    return <div>
        <Typography.Text copyable={{text: getCopyText(content)}}>
            {parts.map((part, index) => {
                switch (part.type) {
                    case 'ticker':
                        return (
                            <Tooltip key={index}
                                     title={`Click to view details for ${part.content}`}>
                                <Typography.Text
                                    onClick={() => handleTickerClick(part.content)}
                                    style={{
                                        color: token.colorPrimary,
                                        fontWeight: 'bold',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {part.content}
                                </Typography.Text>
                            </Tooltip>
                        );
                    case 'bold':
                        return <Typography.Text key={index} strong={true}>
                            {part.content}
                        </Typography.Text>;
                    case 'header':
                        return <Typography.Title key={index} level={5} style={{
                            marginTop: 0,
                            marginBottom: token.marginSM
                        }}>
                            {part.content}
                        </Typography.Title>;
                    default:
                        return <Typography.Text key={index}>
                            {part.content}
                        </Typography.Text>
                }
            })}
        </Typography.Text>
        {retail && <>
            <Divider style={{
                background: token.colorBorder
                // marginBottom: token.marginXXS
            }}/>
            <Typography.Text style={{
                // color: token.colorTextDescription,
                fontSize: token.fontSize,
                fontStyle: "italic",
                marginBottom: token.marginXS,
                display: "block"
            }}>
                {RETAIL_DISCLOSURE_NOTE}
            </Typography.Text>
        </>}
        <TickerDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            ticker={selectedTicker}
        />
    </div>
};


export const ChatMessageContent = ({role, content}) => {
    const {token} = useToken();

    if (typeof content === 'string' && role === 'user') {
        return content ?? ""
    }
    if (typeof content === 'string' && role === 'assistant') {
        return <ContentMarkdown content={content}/>
    }
    if (typeof content === 'string' && role === 'assistant-thinking') {
        return <>
            <Spin size="small" style={{marginRight: token.marginXS}}/>
            {content}
        </>
    }
    return content ?? "";
}


const ChatMessage = ({role, content}) => {
    const {token} = useToken();

    const style = {
        whiteSpace: "pre-line",
        maxWidth: "calc(100% - 64px)",
        width: "fit-content",
        borderWidth: 1,
        borderStyle: "solid",
        padding: `${token.paddingXS}px ${token.paddingSM}px`,
        margin: token.margin,
    };
    const userStyle = {
        background: token.colorBgGreyDark,
        borderColor: token.colorBgGreyDark,
        fontWeight: token.fontWeightStrong,
        borderRadius: `24px 24px 0 24px`,
        marginLeft: "auto",
        border: "none",
    };
    const aiStyle = {
        background: token.colorBgGrey,
        borderColor: token.colorBorderSecondary,
        borderRadius: "24px 24px 24px 0",
        marginRight: "auto",
    };

    return <List.Item style={{
        border: "none",
        ...style,
        ...(role === "user" ? userStyle : aiStyle),
    }}>
        <ChatMessageContent content={content} role={role}/>
    </List.Item>
}


export default ChatMessage;
