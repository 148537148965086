import React, {useEffect, useState} from 'react';
import {
    Button,
    Col, Flex,
    InputNumber,
    List, Modal,
    Popconfirm,
    Row,
    theme,
    Tooltip, Typography
} from "antd";
import {
    ApiTwoTone,
    DeleteOutlined, ExclamationCircleFilled,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import '@root/components/portfolio/Portfolio.less';
import ValueFormatter from "@global/ValueFormatter";
import SecurityForm from "@components/portfolio/position/SecurityForm";


const {useToken} = theme;


const COL_SPAN = {
    ticker: {lg: 5, md: 5, sm: 5, xs: 5},
    name: {lg: 13, md: 13, sm: 9, xs: 9},
    marketValue: {lg: 5, md: 5, sm: 8, xs: 8},
    removeIcon: {lg: 1, md: 1, sm: 2, xs: 2},
}


const ELLIPSIS_STYLE = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}


const EditableField = ({
                           value,
                           submit,
                           tooltip = null,
                           formatter,
                           inputOptions = {},
                           editable = true,
                           id
                       }) => {
    const [input, setInput] = useState(value);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setInput(value)
    }, [value])

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            setEditing(false);
            submit(input);
        }
        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            setInput(value);
            setEditing(false);
        }
    }

    const onBlur = (e) => {
        setInput(value);
        setEditing(false);
    }

    return (
        editing
            ?
            <InputNumber
                value={input}
                onChange={v => setInput(v)}
                formatter={formatter}
                autoFocus
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                controls={false}
                // variant={"borderless"}
                {...inputOptions}
                id={id}
            />
            :
            <Tooltip title={tooltip} mouseEnterDelay={0.5}>
                <Typography.Text
                    onClick={(event) => {
                        if (editable) {
                            setEditing(true)
                        }
                    }}
                    onDoubleClick={(event) => {
                        if (editable) {
                            setEditing(true)
                        }
                    }}
                    style={{whiteSpace: "nowrap"}}
                    id={id}
                >
                    {formatter(input)}
                </Typography.Text>
            </Tooltip>
    )
}


const PositionsTable = ({
                            positions,
                            changePositionMarketValue,
                            updatePosition,
                            deletePosition,
                            rowHeight = 45,
                            viewOnly = false,
                            id
                        }) => {
    const [editIdx, setEditIdx] = useState(null);
    const {token} = useToken();

    return <>
        {!viewOnly &&
            <Flex
                gap={"small"}
                align={"center"}
                justify={"flex-end"}
                style={{
                    color: token.colorTextDescription,
                    fontSize: token.fontSizeSM,
                    lineHeight: 1.2,
                    // margin: "12px 0 4px 0"
                }}
            >
                <ExclamationCircleFilled style={{color: "rgba(0,0,0,0.2)"}}/>
                Click market value to edit
            </Flex>
        }
        <Row
            type="flex"
            gutter={[8, 0]}
            justify="start"
            align="middle"
            style={{
                minHeight: rowHeight,
                fontWeight: token.fontWeightStrong,
                // borderTop: `1px solid ${token.colorBorder}`
            }}
        >
            {!viewOnly && <Col {...COL_SPAN['removeIcon']}/>}
            <Col {...COL_SPAN['ticker']} style={{...ELLIPSIS_STYLE}}>
                Ticker
            </Col>
            <Col{...COL_SPAN['name']} style={{...ELLIPSIS_STYLE}}>
                Name
            </Col>
            <Col {...COL_SPAN['marketValue']}
                 style={{...ELLIPSIS_STYLE, textAlign: 'right'}}>
                Market value
            </Col>
        </Row>
        <List
            dataSource={positions}
            style={{background: "none"}}
            renderItem={(position, index) => {
                return <Row
                    key={index}
                    type="flex"
                    gutter={[8, 0]}
                    justify="start"
                    align="middle"
                    className="portfolio-position-row"
                    style={{
                        minHeight: rowHeight,
                        borderTop: `1px solid ${token.colorBorder}`
                    }}
                >
                    {!viewOnly &&
                        <Col {...COL_SPAN['removeIcon']}>
                            {!position.prohibitDeletion
                                &&
                                <Popconfirm
                                    title={`Are you sure to delete ${position.ticker || "this"} position?`}
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => deletePosition(index)}
                                >
                                    <DeleteOutlined
                                        style={{
                                            cursor: 'pointer',
                                            color: "rgba(0,0,0,0.4)"
                                        }}
                                        id={`${id}-${index}-remove`}
                                    />
                                </Popconfirm>
                            }
                        </Col>
                    }
                    <Col {...COL_SPAN['ticker']}
                         style={{...ELLIPSIS_STYLE}}>
                        {position.ticker}
                        {position.proxy
                            && <Tooltip title={
                                "This asset is not in our database. " +
                                `We use ${position.proxy.label} as a proxy ` +
                                "to calculate portfolio characteristics. " +
                                (viewOnly ? "" : "Click to change proxy or other characteristics")
                            }>
                                <Button
                                    type={"link"}
                                    icon={<ApiTwoTone/>}
                                    onClick={() => {
                                        if (!viewOnly) {
                                            setEditIdx(index);
                                        }
                                    }}
                                />
                            </Tooltip>
                        }
                    </Col>
                    <Col {...COL_SPAN['name']} style={{...ELLIPSIS_STYLE}}>
                        {position.name || "-"}
                    </Col>
                    <Col {...COL_SPAN['marketValue']}
                         style={{...ELLIPSIS_STYLE, textAlign: 'right'}}>
                        <EditableField
                            value={position.market_value}
                            submit={(value => changePositionMarketValue(value ?? 0, index))}
                            formatter={ValueFormatter.currency}
                            inputOptions={{
                                step: 1000,
                                style: {width: "100%", minWidth: 100},
                                align: "right"
                            }}
                            editable={!viewOnly}
                            id={`${id}-${index}-change-mkval`}
                        />
                    </Col>
                </Row>
            }}
        />
        <Modal
            title="Edit asset"
            open={editIdx !== null}
            width={750}
            closable={false}
            maskClosable={true}
            onCancel={v => {
                setEditIdx(null)
            }}
            footer={null}
        >
            <SecurityForm
                handleSubmit={(asset) => {
                    updatePosition(asset, editIdx);
                    setEditIdx(null);
                }}
                asset={positions[editIdx]}
            />
        </Modal>
    </>
}

export default PositionsTable;
