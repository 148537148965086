import React, {useMemo, useState} from "react";
import {Col, Flex, Row, Grid, theme, Tour} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import EnrollmentCard from "@components/retail/portfolio/EnrollmentCard";
import AccountsCard from "@components/retail/portfolio/AccountsCard";
import RiskProfileCard from "@components/retail/portfolio/RiskProfileCard";
import OverviewCard from "@components/retail/portfolio/OverviewCard";
import AnalyticsCard from "@components/retail/portfolio/AnalyticsCard";
import StressTestCard from "@components/retail/portfolio/StressTestCard";
import RetirementMapCard from "@components/retail/portfolio/RetirementMapCard";
import PositionsCard from "@components/retail/portfolio/PositionsCard";
import {useAuth} from "@hooks/AuthHook";
import {BulbOutlined} from "@ant-design/icons";
import {PageTourModal} from "@components/retail/cards/TourButton";

const {useToken} = theme;
const {useBreakpoint} = Grid;


const MyPortfolioPageTour = ({}) => {
    const {openTour, setOpenTour} = useAuth();
    const {token} = useToken();

    const steps = [
        {
            title: "Portfolio accounts and statistics",
            description: <>
                Here you can find portfolio statistics
                and modify your portfolio accounts.
                Note that you can add an account manually or
                link accounts from your institution.
            </>,
            target: "accounts-card-header-title",
        },
        {
            title: "Click to open tour for this card and see more details",
            description: <>
                All the cards on this page has educational tour.
                To open it, find and click {" "}
                <BulbOutlined/>
                {" " } icon
            </>,
            target: "accounts-card-header-tour",
        },
        {
            title: "Click to discuss your portfolio with Holi, our AI assistant",
            target: "holi-float-button"
        },
        {
            title: "Risk profile",
            description: <>
                Here you can find your portfolio risk score. You can fill
                the questionnaire and take the quiz to get
                your risk ability and risk tolerance.
            </>,
            target: "risk-profile-card-header-title",
        },
        {
            title: "Portfolio review",
            description: <>
                Here you can find problem points of your portfolio
            </>,
            target: "overview-card-header-title",
        },
        {
            title: "Portfolio analytics",
            description: <>
                Here you can find analytical charts and Holi's comments on them
            </>,
            target: "analytics-card-header-title",
        },
        {
            title: "Stress tests",
            description: <>
                Here you can find how your portfolio would behave
                in different scenarios
            </>,
            target: "stress-test-card-header-title",
        },
        {
            title: "Retirement map",
            description: <>
                Here you can find the projected dynamics of your wealth
                and the probability of running out of money
            </>,
            target: "retirement-map-card-header-title",
        },
        {
            title: "Positions",
            description: <>
                Here you can find the composition of your portfolio,
                positions metrics and alerts
            </>,
            target: "positions-card-header-title",
        },
    ];

    const existingSteps = useMemo(() => {
        const _steps = [];
        steps.map(step => {
            if(document.getElementById(step.target)) {
                _steps.push({...step, target: () => document.getElementById(step.target)});
            }
        })
        return _steps;
    }, [openTour])

    return <Tour
        type="primary"
        open={openTour === "portfolio-page"}
        onClose={() => setOpenTour(null)}
        steps={existingSteps}
        gap={{radius: token.borderRadius, offset: token.marginXS}}
        zIndex={10010}
        disabledInteraction={true}
    />
}


const MyPortfolio = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const screens = useBreakpoint();
    const {token} = useToken();

    const portfolios = proposal?.current_portfolios;

    return <>{proposal &&
        (portfolios && portfolios.length > 0
                ?
                <>
                    {screens.xl
                        ?
                        <Row gutter={[token.margin, token.margin]}>
                            <Col span={14}>
                                <Flex vertical gap={token.margin}>
                                    <AccountsCard/>
                                    <AnalyticsCard/>
                                    <PositionsCard/>
                                </Flex>
                            </Col>
                            <Col span={10}>
                                <Flex vertical gap={token.margin}>
                                    <RiskProfileCard/>
                                    <OverviewCard/>
                                    <StressTestCard/>
                                    <RetirementMapCard/>
                                </Flex>
                            </Col>
                        </Row>
                        :
                        <Row gutter={[token.margin, token.margin]}>
                            <Col span={24}>
                                <AccountsCard/>
                            </Col>
                            <Col span={24}>
                                <RiskProfileCard/>
                            </Col>
                            <Col span={24}>
                                <OverviewCard/>
                            </Col>
                            <Col span={24}>
                                <AnalyticsCard/>
                            </Col>
                            <Col span={24}>
                                <StressTestCard/>
                            </Col>
                            <Col span={24}>
                                <RetirementMapCard/>
                            </Col>
                            <Col span={24}>
                                <PositionsCard/>
                            </Col>
                        </Row>
                    }
                    <MyPortfolioPageTour/>
                    <PageTourModal tourKey={"portfolio-page"}/>
                </>
                :
                <EnrollmentCard/>
        )
    }</>
}


export default MyPortfolio;
