import {createContext, useContext, useState, useEffect} from 'react';
import {Modal, Result, Button, Space, Checkbox, Form} from 'antd';
import {useRequest, useUpdateEffect} from 'ahooks';
import {acceptTnc} from "@API/ahooks/users";
import {useAuth} from "@hooks/AuthHook";
import dayjs from "dayjs";
import {useAdsTrack} from "@hooks/AdsConversion";


const TncContext = createContext();


export const TncProvider = ({children}) => {
    const [needToSign, setNeedToSign] = useState(null);
    const {user, systemTnCDate, refreshAuth} = useAuth()
    const [form] = Form.useForm();
    const track = useAdsTrack();

    const {runAsync: updateUserTnc, loading} = useRequest(acceptTnc, {
        manual: true
    });

    useEffect(() => {
        let _needToSign = false
        if (user?.tnc_accepted_date) {
            const signedDate = dayjs(user?.tnc_accepted_date)
            const activeDate = dayjs(systemTnCDate())
            console.debug('- user signed tnc at: ' + signedDate.format())
            console.debug('- active tnc date: ' + activeDate.format())
            if (activeDate.isAfter(signedDate)) {
                _needToSign = true
            }
        } else {
            console.debug('- tnc was never signed by the user')
            _needToSign = true
        }

        setNeedToSign(_needToSign)

    }, [user]);

    return (
        <TncContext.Provider value={{user, needToSign}}>
            {children}
            <Modal
                open={needToSign === true}
                footer={null}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Result
                    status="404"
                    title="Please accept the terms"
                    subTitle="Please accept our Terms of Service and Privacy Policy to use Holistico"
                    extra={
                        <Form form={form} onFinish={async () => {
                            await updateUserTnc();
                            track('SignUp', {
                                external_id: user.id,
                                email: user.email,
                            });
                            refreshAuth()
                        }}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>

                                <Space
                                    direction="vertical"
                                    size={0}
                                    style={{
                                        width: 'auto'
                                    }}
                                >
                                    {['privacy', 'terms'].map(type => (
                                        <Form.Item
                                            key={type}
                                            name={type}
                                            valuePropName="checked"
                                            rules={[{required: true, message: `Please accept ${type}`}]}
                                            style={{marginBottom: 8}}
                                        >
                                            <Checkbox
                                                style={{width: '100%', display: 'flex', alignItems: 'flex-start'}}>
                                                I accept the{' '}
                                                <Button
                                                    type="link"
                                                    href={`https://holisticoinvest.com/${type === 'privacy' ? 'privacy-policy' : 'terms-of-service'}`}
                                                    target="_blank"
                                                    style={{padding: 0}}
                                                >
                                                    {type === 'privacy' ? 'Privacy Policy' : 'Terms of Service'}
                                                </Button>
                                            </Checkbox>
                                        </Form.Item>
                                    ))}
                                    <Button type="primary" block onClick={() => form.submit()}>
                                        Continue
                                    </Button>
                                </Space>
                            </div>
                        </Form>
                    }
                />
            </Modal>
        </TncContext.Provider>
    );
};

export const useTnc = () => useContext(TncContext);