import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Button, Flex, Modal,
    theme, Tooltip, Tour,
} from "antd";
import {
    BulbOutlined,
} from "@ant-design/icons";
import "./TourButton.less"
import {useAuth} from "@hooks/AuthHook";
import {useTnc} from "@hooks/TncContext";


const {useToken} = theme;


export const PageTourModal = ({tourKey}) => {
    const {user, setOpenTour, setTourStatus} = useAuth();
    const {needToSign: needToSignTnc} = useTnc();
    const [open, setOpen] = useState(false);

    console.log("user.tour_status", user.tour_status);

    useEffect(() => {
        if (needToSignTnc === false && user?.tour_status && !Object.keys(user?.tour_status).includes(tourKey)) {
            setOpen(true);
        }
    }, [needToSignTnc])

    return <Modal
        title={
            <Flex gap={"small"}>
                <BulbOutlined/>
                Welcome to the educational tour
            </Flex>
        }
        closable={true}
        maskClosable={false}
        open={open}
        onCancel={() => {
            setOpen(false);
        }}
        footer={
            <Flex gap={"small"} justify={"flex-end"}>
                <Button type={"default"} onClick={() => {
                    setOpen(false);
                    setTourStatus(tourKey, "skipped");
                }}>
                    Skip
                </Button>
                <Button type={"primary"} onClick={() => {
                    setOpen(false);
                    setOpenTour(tourKey);
                }}>
                    Start tour
                </Button>
            </Flex>
        }
    >
        This tour will show you what you can find on this page.
        You can take this tour at anytime from the Help center menu.
    </Modal>
}


const TourButton = ({steps, bgColor, hoverBgColor, bordered=false, openTour, setOpenTour, buttonStyle}) => {
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const existingSteps = useMemo(() => {
        const _steps = [];
        steps.map(step => {
            if(document.getElementById(step.target)) {
                _steps.push({...step, target: () => document.getElementById(step.target)});
            }
        })
        return _steps;
    }, [steps, open, openTour])

    return <>
        <Tooltip title={"Start tour"}>
            <Button
                type={"text"}
                onClick={() => setOpen(true)}
                onMouseLeave={() => setHover(false)}
                onMouseEnter={() => setHover(true)}
                style={{
                    background: hover ? hoverBgColor  ?? token.colorBgGreyDark : bgColor?? token.colorBgGrey,
                    borderRadius: 40,
                    border: bordered ? `1px solid ${token.colorBorder}` : "none",
                    ...(buttonStyle ?? {})
                }}
            >
                <BulbOutlined/>
            </Button>
        </Tooltip>
        <Tour
            type="primary"
            open={!!(open || openTour)}
            onClose={() => {
                setOpen(false);
                if (setOpenTour) {
                    setOpenTour(false);
                }
            }}
            steps={existingSteps}
            gap={{radius: token.borderRadius, offset: token.marginXS}}
            zIndex={10010}
            disabledInteraction={true}
        />
    </>
}


export default TourButton;
